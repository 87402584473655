import { BaseResponsePaginationProps } from './config.type';


export type LocationType = {
  lat?: string,
  lng?: string,
}

export enum AttendanceStatus {
  PRESENT = 'PRESENT',
  LATE = 'LATE',
  SICK = 'SICK',
  PERMISSION = 'PERMISSION',
  ALPHA = 'ALPHA',
}


export interface AttendanceProps {
  attendanceId: string;
  userId: string;
  eventId?: string;
  checkInAt: Date;
  checkOutAt?: Date;
  locationIn?: LocationType
  locationOut?: LocationType
  status: AttendanceStatus;
  remark?: string;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface FetchAllAttendanceResponse extends BaseResponsePaginationProps<AttendanceProps> {
  code: string;
  message: string;
  payload: {
    count: number
    prev: string
    next: string
    results: AttendanceProps[]
  }
}

export const initialAttendance: AttendanceProps = {
  attendanceId: '',
  userId: '',
  eventId: '',
  checkInAt: new Date(),
  checkOutAt: new Date(),
  locationIn: {
    lat: '',
    lng: ''
  },
  locationOut: {
    lat: '',
    lng: ''
  },
  status: AttendanceStatus.PRESENT,
  remark: '',
  createdAt: new Date(),
  updatedAt: new Date()
}

export interface AttendanceImportProps {
  email?: string;
  checkInAt?: Date;
  status?: AttendanceStatus;
  invalidStatus?: string;
  remark?: string;
}