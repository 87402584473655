import React, { useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { Card, Space, Spin, Tag, Typography } from "antd";
import { httpRequest } from "../../../helpers/api";
import {
  AppTable,
  formatDate,
  IAppTableColumn,
  NotSet,
  useFetchList,
} from "@qlibs/react-components";

import {
  EQuizType,
  ESubmitType,
  QuizAttemptProperties,
  QuizProperties,
} from "../../../types/quiz.type";
import RowFilter from "@qlibs/react-components/dist/Table/RowFilter";
import { UserProperties } from "../../../services/openapi";
import { getBasePath } from "../helpers/menuPathGenerator";
import useAdditionalDataForList from "../../../hooks/useAdditionalDataForList";
import { EventsProps } from "../../../types/event.type";

interface ILocation {
  quizId: string;
}

export interface UserWithAttemptsProps extends UserProperties {
  quizAttempts: QuizAttemptProperties[];
}

const ByTalent = ({
  quiz,
  roles,
}: {
  quiz: QuizProperties | undefined;
  roles: string;
}) => {
  const { quizId } = useParams<keyof ILocation>() as ILocation;
  const filterRole = { roles };

  const {
    isLoading,
    data,
    pagination,
    changePage,
    changeLimit,
    query,
    setQuery,
    pageQueries,
  } = useFetchList<UserWithAttemptsProps>({
    httpRequest: httpRequest as any,
    endpoint: "users",
    pageQuery: {
      defaultValue: {
        page: 1,
      },
    },
    initialQuery: {
      quizIds: quizId,
      includeData: "quizAttempts",
      ...filterRole,
      sort: "name:ASC",
    },
  });

  const list = useMemo(() => {
    return data.map((item: any) => {
      const lastData: any = (item.quizAttempts ?? []).sort(
        (a: any, b: any) => b.attempt - a.attempt
      )[0];

      item.lastDataStartAt = lastData.startAt;
      item.lastDataScore = lastData.score;
      item.lastDataEventId = lastData.externalData?.eventId;
      item.lastDataFinishedAt = lastData.finishedAt;
      item.lastDataIsFinishedBySystem = lastData.isFinishedBySystem;

      return item;
    });
  }, [data]);

  const { isLoadingAdditionalData, additionalData } = useAdditionalDataForList<{
    [userId: string]: {
      event: EventsProps;
    };
  }>({
    id: "userId",
    data: list,
    injects: [
      {
        injectedId: "lastDataEventId",
        endpoint: "/event?eventIds=",
        endpointId: "eventId",
        returnKey: "event",
      },
    ],
  });

  const columns: IAppTableColumn<UserWithAttemptsProps>[] = [
    {
      title: "TALENT NAME",
      dataIndex: "name",
      key: "user",
      type: "detail",
      keyId: "id",
      showOriginalValue: true,
      render: (name: string, record: UserWithAttemptsProps) => (
        <Link
          className="table-link"
          to={`/${getBasePath()}/${quiz?.quizId}/result/user/${record.userId}`}
        >
          {name}
        </Link>
      ),
    },
    {
      title: "TOTAL ATTEMPT",
      dataIndex: "attempt",
      key: "attempt",
      render: (_: any, record: UserWithAttemptsProps) => (
        <>{record.quizAttempts?.length || 0}</>
      ),
    },
    quiz?.quizType !== EQuizType.SURVEY
      ? {
          title: "MIN & MAX SCORE",
          dataIndex: "minMaxScore",
          key: "minMaxScore",
          render: (_: any, record: UserWithAttemptsProps) => {
            const res = (record.quizAttempts ?? [])
              .filter((q) => q.finishedAt && q.score)
              .map((v) => v.score)
              .filter(Boolean)
              .sort((a: any, b: any) => a - b);

            return (
              <>
                <Space direction="vertical">
                  <Typography.Text strong>
                    Min: {res.length ? res[0] : 0}
                  </Typography.Text>
                  <Typography.Text strong>
                    Max: {res.length ? res[res.length - 1] : 0}
                  </Typography.Text>
                </Space>
              </>
            );
          },
        }
      : {},

    quiz?.quizType !== EQuizType.SURVEY
      ? {
          title: "LATEST SCORE",
          dataIndex: "lastDataScore",
          key: "lastDataScore",
          // render: (_: any, record: UserWithAttemptsProps) => {
          //   const lastData = (record.quizAttempts ?? [])
          //     .filter((q) => q.finishedAt && q.score)
          //     .sort((a, b) => b.attempt - a.attempt)[0];

          //   return (
          //     <>{typeof lastData?.score === "number" ? lastData?.score : 0}</>
          //   );
          // },
        }
      : {},

    quiz?.quizType === EQuizType.SURVEY &&
    quiz?.submitType === ESubmitType.DAILY
      ? {
          title: "LAST START AT",
          dataIndex: "lastDataStartAt",
          key: "lastDataStartAt",
          type: "date",
          // render: (_: any, record: UserWithAttemptsProps) => {
          //   const lastData = (record.quizAttempts ?? []).sort(
          //     (a, b) => b.attempt - a.attempt
          //   )[0];

          //   return (
          //     <>
          //       {lastData ? (
          //         formatDate(lastData?.startAt, "DD MMM YYYY HH:mm") || (
          //           <NotSet />
          //         )
          //       ) : (
          //         <NotSet />
          //       )}
          //     </>
          //   );
          // },
        }
      : {},

    quiz?.quizType === EQuizType.SURVEY &&
    quiz?.submitType === ESubmitType.PER_EVENT
      ? {
          title: "LAST EVENT",
          dataIndex: "lastDataEventId",
          key: "lastDataEventId",
          render: (value, record) =>
            isLoadingAdditionalData ? (
              <Spin />
            ) : additionalData[record.userId]?.event ? (
              additionalData[record.userId]?.event?.title
            ) : (
              <NotSet />
            ),
          // render: (_: any, record: UserWithAttemptsProps) => {
          //   const lastData = (record.quizAttempts ?? []).sort(
          //     (a, b) => b.attempt - a.attempt
          //   )[0];

          //   return (
          //     <>
          //       {lastData ? (
          //         <>{lastData?.externalData?.eventId || <NotSet />}</>
          //       ) : (
          //         <NotSet />
          //       )}
          //     </>
          //   );
          // },
        }
      : {},
    {
      title: "LATEST SUBMIT",
      dataIndex: "latestSubmit",
      key: "latestSubmit",
      render: (_: any, record: UserWithAttemptsProps) => {
        const lastData = (record.quizAttempts ?? [])
          .filter((q) => q.finishedAt)
          .sort((a, b) => b.attempt - a.attempt)[0];

        return (
          <Space direction="vertical">
            <div>
              {lastData && lastData?.finishedAt ? (
                formatDate(lastData?.finishedAt, "DD MMM YYYY HH:mm")
              ) : (
                <NotSet />
              )}
            </div>
            {lastData?.isFinishedBySystem && (
              <Tag color="red">Finished By System</Tag>
            )}
          </Space>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <div
        style={{
          margin: "12px 0px",
        }}
      >
        <RowFilter
          filterValues={{
            search: pageQueries.search,
          }}
          filters={[
            [
              {
                type: "search",
                key: "search",
                label: "Search",
                placeholder: "Search by talent name",
                onChange: (value: any) => {
                  setQuery({
                    ...query,
                    search: value,
                  });
                },
                colSpan: 12,
              },
            ],
          ]}
        />

        <Card bordered={false} size="small" loading={isLoading}>
          <AppTable
            isLoading={isLoading}
            keyId="userId"
            columns={columns.filter((v) => v.key)}
            data={list}
            pagination={pagination}
            onChangeLimit={changeLimit}
            onChangePage={changePage}
          />
        </Card>
      </div>
    </React.Fragment>
  );
};

export default ByTalent;
