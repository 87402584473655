import React, { Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AppLayout from "../screens/layout/AppLayout";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import { Spin } from "antd";
import Logout from "../screens/auth/Logout";
import QRcode from "../screens/qrcode";
import Evaluation from "../screens/evaluation";
import EvaluationDetail from "../screens/evaluation/EvaluationDetail";
import ListAttendanceByTalent from "../screens/attendance/ListAttendanceByTalent";
import SeeResult from "../screens/quiz/Result";
import DetailResultQuiz from "../screens/quiz/Result/DetailResultByHistory";
import { EQuizType } from "../types/quiz.type";
import QuizSurvey from "../screens/quiz/QuizSurvey";
import QuizMicroLearning from "../screens/quiz/QuizMicroLearning";
import DetailResultByTalent from "../screens/quiz/Result/DetailResultByTalent";
import Result from "../screens/quiz/Result";
import EvaluationChangeLogs from "../screens/evaluation/components/EvaluationChangeLogs";
import QuizChangeLogs from "../screens/quiz/QuizChangeLogs";

const NotFound = React.lazy(() => import("../NotFound"));
const Preference = React.lazy(() => import("../screens/preference"));

const Login = React.lazy(() => import("../screens/auth/NewLogin"));
const ForgotPassword = React.lazy(
  () => import("../screens/auth/ForgotPassword")
);
const ResetPassword = React.lazy(() => import("../screens/auth/ResetPassword"));
const PrivacyPolicyPublic = React.lazy(
  () => import("../screens/privacyPolicyPublic")
);

const Dashboard = React.lazy(() => import("../screens/dashboard"));
const Profile = React.lazy(() => import("../screens/profile"));
const ChangeMyPassword = React.lazy(
  () => import("../screens/profile/ChangeMyPassword")
);
const ChangePasswordUser = React.lazy(
  () => import("../screens/user/ChangePassword")
);
const User = React.lazy(() => import("../screens/user"));
const UserEdit = React.lazy(() => import("../screens/user/Edit"));
const UserDetail = React.lazy(() => import("../screens/user/Detail"));

const Attendance = React.lazy(() => import("../screens/attendance"));
const AttendanceEdit = React.lazy(() => import("../screens/attendance/Edit"));
const AttendanceDetail = React.lazy(
  () => import("../screens/attendance/Detail")
);
const AttendanceChangelog = React.lazy(
  () => import("../screens/attendance/Changelog")
);

const Event = React.lazy(() => import("../screens/event"));
const EventEdit = React.lazy(() => import("../screens/event/Edit"));
const EventDetail = React.lazy(() => import("../screens/event/Edit"));

const BasicConfig = React.lazy(() => import("../screens/basicConfig"));

const FAQs = React.lazy(() => import("../screens/faqs"));
const FAQsEdit = React.lazy(() => import("../screens/faqs/edit"));

const FAQGroup = React.lazy(() => import("../screens/faqGroup"));
const FAQGroupEdit = React.lazy(() => import("../screens/faqGroup/edit"));

const Role = React.lazy(() => import("../screens/role"));
const RoleEdit = React.lazy(() => import("../screens/role/Edit"));
const RoleDetail = React.lazy(() => import("../screens/role/Detail"));

const UserStores = React.lazy(() => import("../screens/user/stores"));
const UserStoreEdit = React.lazy(() => import("../screens/user/stores/Edit"));

const UserAddressDetail = React.lazy(
  () => import("../screens/user/userAddress/Detail")
);
const UserAddressEdit = React.lazy(
  () => import("../screens/user/userAddress/Edit")
);

const TncPrivacy = React.lazy(() => import("../screens/tncPrivacy"));

const ListQuiz = React.lazy(() => import("../screens/quiz"));
const DetailQuiz = React.lazy(() => import("../screens/quiz/Detail"));
const EditQuiz = React.lazy(() => import("../screens/quiz/Edit"));
const ArchiveQuiz = React.lazy(() => import("../screens/quiz/Archive"));

const Course = React.lazy(() => import("../screens/course"));
const CourseDetail = React.lazy(() => import("../screens/course/Detail"));
const CourseEdit = React.lazy(() => import("../screens/course/Edit"));

const Banner = React.lazy(() => import("../screens/banner"));
const BannerDetail = React.lazy(() => import("../screens/banner/Detail"));
const BannerEdit = React.lazy(() => import("../screens/banner/Edit"));

const Program = React.lazy(() => import("../screens/program"));
const ProgramDetail = React.lazy(() => import("../screens/program/Detail"));
const ProgramEdit = React.lazy(() => import("../screens/program/Edit"));

const RootNavigator: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <PublicRoute>
              <Suspense fallback={<Spin spinning={true} />}>
                <Login />
              </Suspense>
            </PublicRoute>
          }
        />
        <Route
          path="/login"
          element={
            <PublicRoute>
              <Suspense fallback={<Spin spinning={true} />}>
                <Login />
              </Suspense>
            </PublicRoute>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <PublicRoute>
              <Suspense fallback={<Spin spinning={true} />}>
                <ForgotPassword />
              </Suspense>
            </PublicRoute>
          }
        />
        <Route
          path="/reset-password/:resetType/:sessionId"
          element={
            <PublicRoute>
              <Suspense fallback={<Spin spinning={true} />}>
                <ResetPassword />
              </Suspense>
            </PublicRoute>
          }
        />

        <Route
          path="/privacy-policy"
          element={
            <PublicRoute>
              <Suspense fallback={<Spin spinning={true} />}>
                <PrivacyPolicyPublic />
              </Suspense>
            </PublicRoute>
          }
        />

        <Route path="*" element={<NotFound />} />

        <Route element={<AppLayout />}>
          <Route
            path="/dashboard"
            element={
              <PrivateRoute loginPath="/login">
                <Dashboard />
              </PrivateRoute>
            }
          />

          <Route
            path="/banner"
            element={
              <PrivateRoute loginPath="/login">
                <Banner />
              </PrivateRoute>
            }
          />
          <Route
            path="/banner/add"
            element={
              <PrivateRoute loginPath="/login">
                <BannerEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/banner/:bannerId/edit"
            element={
              <PrivateRoute loginPath="/login">
                <BannerEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/banner/:bannerId"
            element={
              <PrivateRoute loginPath="/login">
                <BannerDetail />
              </PrivateRoute>
            }
          />

          <Route
            path="/profile"
            element={
              <PrivateRoute loginPath="/login">
                <Profile />
              </PrivateRoute>
            }
          />
          <Route
            path="/change-password"
            element={
              <PrivateRoute loginPath="/login">
                <ChangeMyPassword />
              </PrivateRoute>
            }
          />
          <Route
            path="/daily-qrcode"
            element={
              <PrivateRoute loginPath="/login">
                <QRcode type="DAILY" />
              </PrivateRoute>
            }
          />

          <Route
            path="/admin"
            element={
              <PrivateRoute loginPath="/login">
                <User userType="admin" />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/add"
            element={
              <PrivateRoute loginPath="/login">
                <UserEdit userType="admin" />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/:userId/edit"
            element={
              <PrivateRoute loginPath="/login">
                <UserEdit userType="admin" />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/:userId"
            element={
              <PrivateRoute loginPath="/login">
                <UserDetail userType="admin" />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/:userId/change-password"
            element={
              <PrivateRoute loginPath="/login">
                <ChangePasswordUser />
              </PrivateRoute>
            }
          />

          <Route
            path="/talent"
            element={
              <PrivateRoute loginPath="/login">
                <User userType="talent" />
              </PrivateRoute>
            }
          />
          <Route
            path="/talent/add"
            element={
              <PrivateRoute loginPath="/login">
                <UserEdit userType="talent" />
              </PrivateRoute>
            }
          />
          <Route
            path="/talent/:userId/edit"
            element={
              <PrivateRoute loginPath="/login">
                <UserEdit userType="talent" />
              </PrivateRoute>
            }
          />
          <Route
            path="/talent/:userId"
            element={
              <PrivateRoute loginPath="/login">
                <UserDetail userType="talent" />
              </PrivateRoute>
            }
          />
          <Route
            path="/talent/:userId/change-password"
            element={
              <PrivateRoute loginPath="/login">
                <ChangePasswordUser />
              </PrivateRoute>
            }
          />

          <Route
            path="/mentor"
            element={
              <PrivateRoute loginPath="/login">
                <User userType="mentor" />
              </PrivateRoute>
            }
          />
          <Route
            path="/mentor/add"
            element={
              <PrivateRoute loginPath="/login">
                <UserEdit userType="mentor" />
              </PrivateRoute>
            }
          />
          <Route
            path="/mentor/:userId/edit"
            element={
              <PrivateRoute loginPath="/login">
                <UserEdit userType="mentor" />
              </PrivateRoute>
            }
          />
          <Route
            path="/mentor/:userId"
            element={
              <PrivateRoute loginPath="/login">
                <UserDetail userType="mentor" />
              </PrivateRoute>
            }
          />
          <Route
            path="/mentor/:userId/change-password"
            element={
              <PrivateRoute loginPath="/login">
                <ChangePasswordUser />
              </PrivateRoute>
            }
          />

          <Route
            path="/monev"
            element={
              <PrivateRoute loginPath="/login">
                <User userType="monev" />
              </PrivateRoute>
            }
          />
          <Route
            path="/monev/add"
            element={
              <PrivateRoute loginPath="/login">
                <UserEdit userType="monev" />
              </PrivateRoute>
            }
          />
          <Route
            path="/monev/:userId/edit"
            element={
              <PrivateRoute loginPath="/login">
                <UserEdit userType="monev" />
              </PrivateRoute>
            }
          />
          <Route
            path="/monev/:userId"
            element={
              <PrivateRoute loginPath="/login">
                <UserDetail userType="monev" />
              </PrivateRoute>
            }
          />
          <Route
            path="/monev/:userId/change-password"
            element={
              <PrivateRoute loginPath="/login">
                <ChangePasswordUser />
              </PrivateRoute>
            }
          />

          <Route
            path="/coordinator"
            element={
              <PrivateRoute loginPath="/login">
                <User userType="coordinator" />
              </PrivateRoute>
            }
          />
          <Route
            path="/coordinator/add"
            element={
              <PrivateRoute loginPath="/login">
                <UserEdit userType="coordinator" />
              </PrivateRoute>
            }
          />
          <Route
            path="/coordinator/:userId/edit"
            element={
              <PrivateRoute loginPath="/login">
                <UserEdit userType="coordinator" />
              </PrivateRoute>
            }
          />
          <Route
            path="/coordinator/:userId"
            element={
              <PrivateRoute loginPath="/login">
                <UserDetail userType="coordinator" />
              </PrivateRoute>
            }
          />
          <Route
            path="/coordinator/:userId/change-password"
            element={
              <PrivateRoute loginPath="/login">
                <ChangePasswordUser />
              </PrivateRoute>
            }
          />

          <Route
            path="/customer"
            element={
              <PrivateRoute loginPath="/login">
                <User userType="customer" />
              </PrivateRoute>
            }
          />
          <Route
            path="/customer/add"
            element={
              <PrivateRoute loginPath="/login">
                <UserEdit userType="customer" />
              </PrivateRoute>
            }
          />
          <Route
            path="/customer/:userId/edit"
            element={
              <PrivateRoute loginPath="/login">
                <UserEdit userType="customer" />
              </PrivateRoute>
            }
          />
          <Route
            path="/customer/:userId"
            element={
              <PrivateRoute loginPath="/login">
                <UserDetail userType="customer" />
              </PrivateRoute>
            }
          />
          <Route
            path="/customer/:userId/change-password"
            element={
              <PrivateRoute loginPath="/login">
                <ChangePasswordUser />
              </PrivateRoute>
            }
          />

          <Route
            path="/basic-configurations"
            element={
              <PrivateRoute loginPath="/login">
                <BasicConfig />
              </PrivateRoute>
            }
          />

          <Route
            path="/daily-attendance"
            element={
              <PrivateRoute loginPath="/login">
                <Attendance type="DAILY" />
              </PrivateRoute>
            }
          />

          <Route
            path="/daily-attendance/add"
            element={
              <PrivateRoute loginPath="/login">
                <AttendanceEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/daily-attendance/:attendanceId/edit"
            element={
              <PrivateRoute loginPath="/login">
                <AttendanceEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/daily-attendance/:attendanceId"
            element={
              <PrivateRoute loginPath="/login">
                <AttendanceDetail />
              </PrivateRoute>
            }
          />

          <Route
            path="/daily-attendance-by-talent"
            element={
              <PrivateRoute loginPath="/login">
                <ListAttendanceByTalent />
              </PrivateRoute>
            }
          />

          <Route
            path="/daily-attendance/changelog/:attendanceId"
            element={
              <PrivateRoute loginPath="/login">
                <AttendanceChangelog />
              </PrivateRoute>
            }
          />

          <Route
            path="/schedule"
            element={
              <PrivateRoute loginPath="/login">
                <Event />
              </PrivateRoute>
            }
          />
          <Route
            path="/schedule/add"
            element={
              <PrivateRoute loginPath="/login">
                <EventEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/schedule/add/:date"
            element={
              <PrivateRoute loginPath="/login">
                <EventEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/schedule/:eventId"
            element={
              <PrivateRoute loginPath="/login">
                <EventDetail />
              </PrivateRoute>
            }
          />

          <Route
            path="/evaluation"
            element={
              <PrivateRoute loginPath="/login">
                <Evaluation />
              </PrivateRoute>
            }
          />
          <Route
            path="/evaluation/:userId"
            element={
              <PrivateRoute loginPath="/login">
                <EvaluationDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="/evaluation/:userId/input"
            element={
              <PrivateRoute loginPath="/login">
                <EvaluationDetail mode="input" />
              </PrivateRoute>
            }
          />

          <Route
            path="/evaluation-change-logs"
            element={
              <PrivateRoute loginPath="/login">
                <EvaluationChangeLogs />
              </PrivateRoute>
            }
          />

          <Route
            path="/users/stores"
            element={
              <PrivateRoute loginPath="/login">
                <UserStores userType="stores" />
              </PrivateRoute>
            }
          />
          <Route
            path="/users/stores/add"
            element={
              <PrivateRoute loginPath="/login">
                <UserStoreEdit />
              </PrivateRoute>
            }
          />

          <Route
            path="/users/stores/:storeId/:userId"
            element={
              <PrivateRoute loginPath="/login">
                <UserDetail userType="admin" />
              </PrivateRoute>
            }
          />

          <Route
            path="/users/stores/:storeId/:userId/edit"
            element={
              <PrivateRoute loginPath="/login">
                <UserStoreEdit />
              </PrivateRoute>
            }
          />

          <Route
            path="/faqs"
            element={
              <PrivateRoute loginPath="/login">
                <FAQs />
              </PrivateRoute>
            }
          />
          <Route
            path="/faqs/add"
            element={
              <PrivateRoute loginPath="/login">
                <FAQsEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/faqs/:faqId/edit"
            element={
              <PrivateRoute loginPath="/login">
                <FAQsEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/faq-group"
            element={
              <PrivateRoute loginPath="/login">
                <FAQGroup />
              </PrivateRoute>
            }
          />
          <Route
            path="/faq-group/add"
            element={
              <PrivateRoute loginPath="/login">
                <FAQGroupEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/faq-group/:faqGroupId/edit"
            element={
              <PrivateRoute loginPath="/login">
                <FAQGroupEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/role"
            element={
              <PrivateRoute loginPath="/login">
                <Role />
              </PrivateRoute>
            }
          />
          <Route
            path="/role/add"
            element={
              <PrivateRoute loginPath="/login">
                <RoleEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/role/:roleId/edit"
            element={
              <PrivateRoute loginPath="/login">
                <RoleEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/role/:roleId"
            element={
              <PrivateRoute loginPath="/login">
                <RoleDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="/preference"
            element={
              <PrivateRoute loginPath="/login">
                <Preference />
              </PrivateRoute>
            }
          />
          <Route
            path="/users/:userId/address/:addressId/edit"
            element={
              <PrivateRoute loginPath="/login">
                <UserAddressEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/users/:userId/address/:addressId"
            element={
              <PrivateRoute loginPath="/login">
                <UserAddressDetail />
              </PrivateRoute>
            }
          />

          <Route
            path="/tnc-and-privacy-policy"
            element={
              <PrivateRoute loginPath="/login">
                <TncPrivacy />
              </PrivateRoute>
            }
          />

          {/** TEST */}
          <Route
            path="/pretest--posttest"
            element={
              <PrivateRoute loginPath="/login">
                <ListQuiz quizType={[EQuizType.PRETEST, EQuizType.POSTEST]} />
              </PrivateRoute>
            }
          />

          <Route
            path="/pretest--posttest/add"
            element={
              <PrivateRoute loginPath="/login">
                <EditQuiz quizType={[EQuizType.PRETEST, EQuizType.POSTEST]} />
              </PrivateRoute>
            }
          />

          <Route
            path="/pretest--posttest/:quizId/result"
            element={
              <PrivateRoute loginPath="/login">
                <SeeResult />
              </PrivateRoute>
            }
          />

          <Route
            path="/pretest--posttest/:quizId/result/:quizAttemptId"
            element={
              <PrivateRoute loginPath="/login">
                <DetailResultQuiz />
              </PrivateRoute>
            }
          />
          <Route
            path="/pretest--posttest/:quizId/result/user/:userId"
            element={
              <PrivateRoute loginPath="/login">
                <DetailResultByTalent />
              </PrivateRoute>
            }
          />

          <Route
            path="/pretest--posttest/:quizId/edit"
            element={
              <PrivateRoute loginPath="/login">
                <EditQuiz quizType={[EQuizType.PRETEST, EQuizType.POSTEST]} />
              </PrivateRoute>
            }
          />

          <Route
            path="/pretest--posttest/:quizId"
            element={
              <PrivateRoute loginPath="/login">
                <DetailQuiz />
              </PrivateRoute>
            }
          />

          {/** MICRO LEARNING */}
          <Route
            path="/micro-learning"
            element={
              <PrivateRoute loginPath="/login">
                <QuizMicroLearning />
              </PrivateRoute>
            }
          />

          <Route
            path="/micro-learning/add"
            element={
              <PrivateRoute loginPath="/login">
                <EditQuiz
                  quizType={[
                    EQuizType.MICRO_LEARNING,
                    EQuizType.MICRO_LEARNING_PUBLIC,
                  ]}
                />
              </PrivateRoute>
            }
          />

          <Route
            path="/micro-learning/:quizId/result"
            element={
              <PrivateRoute loginPath="/login">
                <SeeResult />
              </PrivateRoute>
            }
          />

          <Route
            path="/micro-learning/:quizId/result/:quizAttemptId"
            element={
              <PrivateRoute loginPath="/login">
                <DetailResultQuiz />
              </PrivateRoute>
            }
          />
          <Route
            path="/micro-learning/:quizId/result/user/:userId"
            element={
              <PrivateRoute loginPath="/login">
                <DetailResultByTalent />
              </PrivateRoute>
            }
          />

          <Route
            path="/micro-learning/:quizId/edit"
            element={
              <PrivateRoute loginPath="/login">
                <EditQuiz
                  quizType={[
                    EQuizType.MICRO_LEARNING,
                    EQuizType.MICRO_LEARNING_PUBLIC,
                  ]}
                />
              </PrivateRoute>
            }
          />

          <Route
            path="/micro-learning/:quizId"
            element={
              <PrivateRoute loginPath="/login">
                <DetailQuiz />
              </PrivateRoute>
            }
          />

          {/** SURVEY */}
          <Route
            path="/survey"
            element={
              <PrivateRoute loginPath="/login">
                <QuizSurvey />
              </PrivateRoute>
            }
          />

          <Route
            path="/survey/add"
            element={
              <PrivateRoute loginPath="/login">
                <EditQuiz quizType={[EQuizType.SURVEY]} />
              </PrivateRoute>
            }
          />

          <Route
            path="/survey/:quizId/result"
            element={
              <PrivateRoute loginPath="/login">
                <SeeResult />
              </PrivateRoute>
            }
          />

          <Route
            path="/survey/:quizId/result/:quizAttemptId"
            element={
              <PrivateRoute loginPath="/login">
                <DetailResultQuiz />
              </PrivateRoute>
            }
          />
          <Route
            path="/survey/:quizId/result/user/:userId"
            element={
              <PrivateRoute loginPath="/login">
                <DetailResultByTalent />
              </PrivateRoute>
            }
          />

          <Route
            path="/survey/:quizId/edit"
            element={
              <PrivateRoute loginPath="/login">
                <EditQuiz quizType={[EQuizType.SURVEY]} />
              </PrivateRoute>
            }
          />

          <Route
            path="/survey/:quizId"
            element={
              <PrivateRoute loginPath="/login">
                <DetailQuiz />
              </PrivateRoute>
            }
          />

          {/* Archive Quiz */}
          <Route
            path="/archive-quiz"
            element={
              <PrivateRoute loginPath="/login">
                <ArchiveQuiz />
              </PrivateRoute>
            }
          />
          <Route
            path="/archive-quiz/:quizId"
            element={
              <PrivateRoute loginPath="/login">
                <DetailQuiz isArchive={true} />
              </PrivateRoute>
            }
          />
          <Route
            path="/archive-quiz/:quizId/result"
            element={
              <PrivateRoute loginPath="/login">
                <Result isArchive={true} />
              </PrivateRoute>
            }
          />
          <Route
            path="/archive-quiz/:quizId/result/user/:userId"
            element={
              <PrivateRoute loginPath="/login">
                <DetailResultByTalent />
              </PrivateRoute>
            }
          />

          <Route
            path="/quiz-change-logs"
            element={
              <PrivateRoute loginPath="/login">
                <QuizChangeLogs />
              </PrivateRoute>
            }
          />

          {/** TRAINING */}
          <Route
            path="/training"
            element={
              <PrivateRoute loginPath="/login">
                <Course />
              </PrivateRoute>
            }
          />
          <Route
            path="/training/:courseId/"
            element={
              <PrivateRoute loginPath="/login">
                <CourseDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="/training/add"
            element={
              <PrivateRoute loginPath="/login">
                <CourseEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/training/:courseId/edit"
            element={
              <PrivateRoute loginPath="/login">
                <CourseEdit />
              </PrivateRoute>
            }
          />

          {/** PROGRAM */}
          <Route
            path="/program"
            element={
              <PrivateRoute loginPath="/login">
                <Program />
              </PrivateRoute>
            }
          />
          <Route
            path="/program/:programId/"
            element={
              <PrivateRoute loginPath="/login">
                <ProgramDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="/program/add"
            element={
              <PrivateRoute loginPath="/login">
                <ProgramEdit />
              </PrivateRoute>
            }
          />
          <Route
            path="/program/:programId/edit"
            element={
              <PrivateRoute loginPath="/login">
                <ProgramEdit />
              </PrivateRoute>
            }
          />

          <Route
            path="/logout"
            element={
              <PublicRoute>
                <Logout />
              </PublicRoute>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
export default RootNavigator;
