import React from "react";
import {
  AppTable,
  HeaderSection,
  IAppTableColumn,
  useFetchList,
  PAGE_SIZE_OPTIONS,
  NotSet,
} from "@qlibs/react-components";
import { httpRequest } from "../../helpers/api";
import RowFilter from "@qlibs/react-components/dist/Table/RowFilter";
import { UserProperties } from '../user/types/user.type';
import useAdditionalDataForList from '../../hooks/useAdditionalDataForList';

const Evaluation = () => {
  const filterRole = { roles: "talent" };

  const {
    isLoading,
    data,
    pagination,
    changePage,
    changeLimit,
    handleSearch,
    pageQueries,
    filterDropdown,
  } = useFetchList<UserProperties>({
    httpRequest: httpRequest as any,
    endpoint: "users",
    limit: +PAGE_SIZE_OPTIONS[0],
    initialQuery: {
      ...filterRole,
      sort: 'name:ASC'
    },
    pageQuery: {
      defaultValue: {
        page: 1,
      },
    },
  });

  const { additionalData } = useAdditionalDataForList<{
    [key: string]: { mentor?: any };
  }>({
    id: 'userId',
    data,
    injects: [
      {
        injectedId: 'userId',
        endpoint: '/mentor-talent/main-mentors?talentIds=',
        endpointId: 'talentId',
        returnKey: 'mentor',
      },
    ],
  });

  const columns: IAppTableColumn<UserProperties>[] = [
    {
      title: "NAME",
      dataIndex: "name",
      key: "name",
      type: "detail",
      showOriginalValue: true,
      keyId: "userId",
    },
    {
      title: "EMAIL",
      dataIndex: "email",
      key: "email",
    },
    // {
    //   title: "PHONE NUMBER",
    //   dataIndex: "phone",
    //   key: "phone",
    // },
    {
      title: 'MAIN MENTOR',
      dataIndex: 'mentor',
      key: 'mentor',
      render: (_, record) =>
        additionalData[record.userId]?.mentor?.mentor?.userId ? (
          <a
            href={`/mentor/${
              additionalData[record.userId]?.mentor?.mentor?.userId
            }`}
            style={{fontSize: 12}}
          >
            {additionalData[record.userId]?.mentor?.mentor?.name}
          </a>
        ) : (
          <NotSet />
        ),
        },
    // {
    //   title: "LATEST EVALUATION",
    //   dataIndex: "createdAt",
    //   key: "createdAt",
    //   type: "datetime",
    // },
    {
      title: "ACTION",
      key: "action",
      type: "actions",
      actions: [
        "detail",
      ],
    },
  ];

  return (
    <React.Fragment>
      <HeaderSection title="Evaluation" />

      <RowFilter
        filterValues={{
          search: pageQueries.search,
          status: pageQueries.status || "all",
        }}
        filters={[
          [
            {
              type: "search",
              key: "search",
              label: "Search Talent",
              placeholder: "Search talent by name, email, or phone",
              onChange: (value: any) => {
                handleSearch(value);
              },
              colSpan: 18,
            },
            {
              type: "select",
              key: "status",
              label: "Status",
              options: [
                {
                  value: "all",
                  label: "All",
                },
                {
                  value: "active",
                  label: "Active",
                },
                {
                  value: "inactive",
                  label: "Inactive",
                },
              ],
              onChange: (value: any) => {
                filterDropdown({
                  status: value === "all" ? "" : value,
                });
              },
              colSpan: 6,
            },
          ],
        ]}
      />

      <AppTable
        isLoading={isLoading}
        keyId="userId"
        columns={columns}
        data={data}
        pagination={pagination}
        onChangePage={changePage}
        onChangeLimit={changeLimit}
        // _table={{
        //   scroll:{ x: '100%' }
        // } as any}
      />
    </React.Fragment>
  );
};
export default Evaluation;
