import React from 'react';
import { Upload, Button, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import type { RcFile } from 'antd/es/upload/interface';
import * as XLSX from 'xlsx';

export interface RowData {
  [key: string]: string | number | null;
}

interface ExcelParserProps {
  setParsedData: (data: RowData[]) => void;
}

const ExcelParser: React.FC<ExcelParserProps> = ({ setParsedData }) => {
  const handleFileUpload = async (file: RcFile) => {
    try {
      const parsedData = await readExcelFile(file);
      setParsedData(parsedData); // Pass raw parsed data to parent
      message.success('File uploaded successfully!');
    } catch (error) {
      console.error('Error reading Excel file:', error);
      message.error('Failed to upload the file.');
    }

    return false; // Prevent automatic upload
  };

  const beforeUpload = (file: RcFile): boolean => {
    const isExcel = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'application/vnd.ms-excel';
    if (!isExcel) {
      message.error('You can only upload Excel files (.xlsx or .xls).');
    }
    return isExcel; // Allow upload only if the file is an Excel file
  };

  return (
    <div>
      <Upload 
        beforeUpload={(file) => beforeUpload(file) && handleFileUpload(file)} 
        accept=".xlsx, .xls"
        showUploadList={false}
      >
        <Button icon={<UploadOutlined />}>Upload Excel</Button>
      </Upload>
    </div>
  );
};

const readExcelFile = (file: File): Promise<RowData[]> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    
    reader.onload = (e) => {
      if (!e.target?.result) {
        reject(new Error('Failed to read file.'));
        return;
      }

      const data = new Uint8Array(e.target.result as ArrayBuffer);
      const workbook = XLSX.read(data, { type: 'array' });

      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json<string[]>(worksheet, { header: 1 });

      const headers = jsonData[0];
      const rows = jsonData.slice(1);
      
      const result: RowData[] = rows.map(row => {
        let rowObject: RowData = {};
        row.forEach((cell, index) => {
          if (typeof cell === 'string') {
            let processedCell = cell.replace(/\r\n/g, '\n').replace(/^\n+/, ''); // Replace \r\n and remove leading \n
            rowObject[headers[index]] = processedCell;
          } else {
            rowObject[headers[index]] = cell;
          }
        });
        return rowObject;
      });

      resolve(result);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsArrayBuffer(file);
  });
};

export default ExcelParser;
