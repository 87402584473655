import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { UserWithAttemptsProps } from "./ByTalent";
import {
  AppTable,
  BaseResponseProps,
  HeaderSection,
  IAppTableColumn,
  NotSet,
  generateQueryString,
  formatDate,
  DetailItem,
} from "@qlibs/react-components";
import { httpRequest } from "../../../helpers/api";
import useDetailBreadcrumbs from "../../../hooks/useDetailBreadcrumbs";
import {
  Button,
  Card,
  Col,
  Divider,
  Empty,
  Modal,
  Row,
  Space,
  Spin,
  Tag,
  Typography,
} from "antd";
// import DetailItem from "../../../components/DetailItem";
import SimpleDetailItem from "../../../components/SimpleDetailItem";
import {
  EQuizType,
  ESubmitType,
  QuizAttemptProperties,
  QuizProperties,
} from "../../../types/quiz.type";
import { getBasePath } from "../helpers/menuPathGenerator";
import ModalAddNotes from "../components/ModalAddNotes";
import DetailResultQuiz from "./DetailResultByHistory";
import useAdditionalDataForList from "../../../hooks/useAdditionalDataForList";
import { EventsProps } from "../../../types/event.type";
import { dateRangeInMinutes } from "../../../helpers/datetime";

interface ILocation {
  quizId: string;
  userId: string;
}

const { Title, Text } = Typography;

type Props = {
  quizId?: string;
  userId?: string;
  hideHeader?: boolean;
  detailButtonMode?: "modal";
};
export default function DetailResultByTalent({
  quizId: quizIdFromProps,
  userId: userIdFromProps,
  ...props
}: Props) {
  const { quizId: quizIdFromParams, userId: userIdFromParams } = useParams<
    keyof ILocation
  >() as ILocation;
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [user, setUser] = React.useState<UserWithAttemptsProps | undefined>(
    undefined
  );
  const [quiz, setQuiz] = React.useState<QuizProperties | undefined>(undefined);
  const [showModalAddNotes, setShowModalAddNotes] =
    React.useState<boolean>(false);
  const [selectedQuizAttempt, setSelectedQuizAttempt] = React.useState<
    QuizAttemptProperties | undefined
  >(undefined);
  const [isFetch, setIsFetch] = React.useState<boolean>(true);
  const [showModalDetailAttempt, setShowModalDetailAttempt] = useState<{
    quizId: string;
    quizAttemptId: string;
    nAttempt: number;
  }>();

  const quizId = quizIdFromProps || quizIdFromParams;
  const userId = userIdFromProps || userIdFromParams;

  const { setBreadcrumbDetails } = useDetailBreadcrumbs();

  const { isLoadingAdditionalData, additionalData } = useAdditionalDataForList<{
    [quizAttemptId: string]: { event: EventsProps };
  }>({
    ignore: props.detailButtonMode === "modal",
    id: "attempt",
    data: user?.quizAttempts || [],
    injects: [
      {
        injectedId: ["externalData", "eventId"],
        endpoint: "event?eventIds=",
        returnKey: "event",
        endpointId: "eventId",
      },
    ],
  });
  console.info("additionalData", additionalData);

  useEffect(() => {
    if (
      props.hideHeader &&
      user?.quizAttempts &&
      user?.quizAttempts.length === 1
    ) {
      setShowModalDetailAttempt({
        nAttempt: user?.quizAttempts[0].attempt,
        quizId: user?.quizAttempts[0].quizId,
        quizAttemptId: user?.quizAttempts[0].id,
      });
    }
  }, [user]);

  //   const {
  //     isLoading: isLoadingAttempt,
  //     data,
  //     pagination,
  //     changePage,
  //     changeLimit,
  //     query,
  //     setQuery,
  //   } = useFetchList<QuizAttemptProperties>({
  //     httpRequest: httpRequest as any,
  //     endpoint: "quiz-attempt",
  //     pageQuery: {
  //       defaultValue: {
  //         page: 1,
  //       },
  //     },
  //     initialQuery: {
  //       quizIds: quizId,
  //       userIds: userId,
  //     },
  //   });

  const columns: IAppTableColumn<QuizAttemptProperties>[] = [
    {
      title: "ATTEMPT",
      dataIndex: "attempt",
      key: "attempt",
      width: 120,
    },
    quiz?.quizType !== EQuizType.SURVEY
      ? {
          title: "SCORE",
          dataIndex: "score",
          key: "score",
          render: (v: number) => <>{v ? v : <NotSet />}</>,
        }
      : {},

    quiz?.quizType === EQuizType.SURVEY &&
    quiz?.submitType === ESubmitType.PER_EVENT &&
    props.detailButtonMode !== "modal"
      ? {
          title: "EVENT",
          dataIndex: ["externalData", "eventId"],
          key: "eventId",
          render: (v: string, record) => {
            if (isLoadingAdditionalData) {
              return <Spin />;
            } else if (additionalData[record.attempt as any]?.event) {
              return (
                additionalData[record.attempt as any]?.event?.title ||
                "Unknown Event"
              );
            } else {
              return <NotSet />;
            }
          },
        }
      : {},
    {
      title: "NOTES FROM MENTOR",
      dataIndex: "notesFromMentor",
      key: "notesFromMentor",
      render: (notes: string, record: QuizAttemptProperties) => (
        <Space direction="vertical">
          {notes && <Typography.Text>{notes}</Typography.Text>}
          <Button
            type="link"
            onClick={() => {
              setShowModalAddNotes(true);
              setSelectedQuizAttempt(record);
            }}
            style={{
              fontSize: 12,
              width: "initial",
              padding: 0,
            }}
          >
            {notes ? "Edit" : "Add"} Notes
          </Button>
        </Space>
      ),
    },
    {
      title: "START AT",
      dataIndex: "startAt",
      key: "startAt",
      type: "datetime",
    },
    {
      title: "FINISHED AT",
      dataIndex: "finishedAt",
      key: "finishedAt",
      type: "datetime",
      render: (date: Date, record: QuizAttemptProperties) => (
        <Space direction="vertical">
          <div>{date ? formatDate(date, "DD MMM YYYY HH:mm") : <NotSet />}</div>
          {record?.isFinishedBySystem && (
            <Tag color="red">Finished By System</Tag>
          )}
        </Space>
      ),
    },
    {
      title: "DURATION",
      dataIndex: "duration",
      key: "duration",
      render: (date: Date, record: QuizAttemptProperties) => {
        let duration;
        if (record.startAt && record.finishedAt) {
          duration = dateRangeInMinutes(record.startAt!, record.finishedAt);
        }
        return <>{!duration ? <NotSet /> : `${duration} Minutes`}</>;
      },
    },
    {
      title: "ACTION",
      key: "action",
      type: "actions",
      render: (_: any, record: QuizAttemptProperties) => (
        <>
          {props.detailButtonMode === "modal" ? (
            showModalDetailAttempt?.quizAttemptId === record.id ? (
              <></>
            ) : (
              <Button
                type="link"
                onClick={() => {
                  setShowModalDetailAttempt(undefined);
                  setTimeout(() => {
                    setShowModalDetailAttempt({
                      quizId: record.quizId,
                      quizAttemptId: record.id,
                      nAttempt: record.attempt,
                    });
                  }, 500);
                  // setTimeout(() => {
                  //   window.scrollTo({ top: 500 });
                  // }, 1000);
                }}
              >
                Detail
              </Button>
            )
          ) : (
            <Button
              type="link"
              href={`/${getBasePath()}/${quizId}/result/${record.id}`}
            >
              Detail
            </Button>
          )}
        </>
      ),
    },
  ];

  const fetchUser = async () => {
    try {
      setIsLoading(true);
      const res = await httpRequest.get<
        BaseResponseProps<UserWithAttemptsProps>
      >(
        `/users/${userId}${generateQueryString({
          quizIds: quizId,
          includeData: "quizAttempts",
        })}`
      );
      if (res && res.data && res.data.payload) {
        setUser(res.data.payload);

        const resQuiz = await httpRequest.get<
          BaseResponseProps<QuizProperties>
        >("/quizzes/" + quizId);
        setQuiz({
          ...resQuiz.data.payload,
        });
      }

      const bcDetails = [
        {
          field: "userId",
          value: userId,
          label: res.data.payload.name,
        },
      ];
      setBreadcrumbDetails(bcDetails);
      setIsLoading(false);
    } catch (error: any) {
      console.log(error);
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    if (isFetch) {
      if (userId) {
        fetchUser();
      }

      setIsFetch(false);
    }
  }, [userId, isFetch]);

  const arrScore = useMemo(() => {
    if (!user) return [];
    const res = (user.quizAttempts ?? [])
      .filter((q) => q.finishedAt && q.score)
      .map((v) => v.score)
      .filter(Boolean)
      .sort((a: any, b: any) => a - b);

    return res;
  }, [user]);

  const latestScore = useMemo(() => {
    if (!user) return 0;
    const res = (user.quizAttempts ?? [])
      .filter((q) => q.finishedAt && q.score)
      .sort((a, b) => b.attempt - a.attempt);

    return res.length ? res[0]?.score : 0;
  }, [user]);

  const latestSubmit = useMemo(() => {
    if (!user) return 0;
    const res = (user.quizAttempts ?? [])
      .filter((q) => q.finishedAt)
      .sort((a, b) => b.attempt - a.attempt);

    return res.length && res[0]?.finishedAt ? res[0]?.finishedAt : null;
  }, [user]);

  return (
    <React.Fragment>
      <ModalAddNotes
        open={showModalAddNotes}
        onClose={() => {
          setShowModalAddNotes(false);
          setSelectedQuizAttempt(undefined);
        }}
        quizAttempt={selectedQuizAttempt}
        onSuccess={() => {
          setIsFetch(true);
        }}
      />

      {!props.hideHeader && (
        <HeaderSection
          icon="back"
          title={`RESULT QUIZ "${quiz?.title}" BY ${user?.name}`}
        />
      )}
      <Spin spinning={isLoading || isLoading}>
        {!isLoading && !user?.userId ? (
          <Empty description="Submissions is still empty for now" />
        ) : (
          <Card bordered={false}>
            <Row
              style={{
                marginBottom: 20,
              }}
            >
              <Col span={5}>
                <Title level={5}>Data Attempt</Title>
                <Text style={{ color: "#768499" }}>
                  These are attempt detail, you can’t change here
                </Text>
              </Col>
              <Col offset={1} span={16}>
                <Row gutter={[12, 12]}>
                  <Col span={12}>
                    <DetailItem label="Talent Name" value={user?.name} />
                  </Col>
                  <Col span={12}>
                    <DetailItem label="Role" value={user?.role?.roleName} />
                  </Col>
                  <Col span={12}>
                    <DetailItem
                      label="Total Attempt"
                      value={user?.quizAttempts?.length}
                    />
                  </Col>
                  {quiz?.quizType !== EQuizType.SURVEY && (
                    <>
                      <Col span={12}>
                        <SimpleDetailItem label="Min & Max Score">
                          <Space direction="vertical">
                            <Typography.Text strong>
                              Min: {arrScore.length ? arrScore[0] : 0}
                            </Typography.Text>
                            <Typography.Text strong>
                              Max:{" "}
                              {arrScore.length
                                ? arrScore[arrScore.length - 1]
                                : 0}
                            </Typography.Text>
                          </Space>
                        </SimpleDetailItem>
                      </Col>
                      <Col span={12}>
                        <DetailItem label="Latest Score" value={latestScore} />
                      </Col>
                    </>
                  )}

                  <Col span={12}>
                    <DetailItem
                      label="Latest Submit"
                      value={latestSubmit}
                      type="datetime"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            {!props.hideHeader ? (
              <AppTable
                isLoading={isLoading}
                keyId="id"
                columns={columns.filter((v) => v.key)}
                data={user?.quizAttempts || []}
                pagination={false}
                // onChangeLimit={changeLimit}
                // onChangePage={changePage}
              />
            ) : props.hideHeader && (user?.quizAttempts || []).length > 1 ? (
              <AppTable
                isLoading={isLoading}
                keyId="id"
                columns={columns.filter((v) => v.key)}
                data={user?.quizAttempts || []}
                pagination={false}
                // onChangeLimit={changeLimit}
                // onChangePage={changePage}
              />
            ) : (
              false
            )}
          </Card>
        )}
      </Spin>

      {showModalDetailAttempt?.quizId &&
      showModalDetailAttempt?.quizAttemptId ? (
        // <Modal
        //   width={900}
        //   open={true}
        //   closable
        //   footer={false}
        //   onCancel={() => {
        //     setShowModalDetailAttempt(undefined);
        //   }}
        //   onClose={() => {
        //     setShowModalDetailAttempt(undefined);
        //   }}
        // >
        // </Modal>
        <Row justify="center">
          <Divider />
          <Title style={{ textAlign: "center" }} level={4}>
            Attempt Detail ({showModalDetailAttempt.nAttempt})
          </Title>
          <DetailResultQuiz
            quizId={showModalDetailAttempt.quizId}
            quizAttemptId={showModalDetailAttempt.quizAttemptId}
            hideHeader
            onSuccessChangeNotes={() => {
              //
              setIsFetch(true);
            }}
          />
        </Row>
      ) : (
        false
      )}
    </React.Fragment>
  );
}
