import React, { useState } from "react";
import { CheckCircleOutlined, CloseCircleOutlined, EditOutlined, IssuesCloseOutlined, PauseCircleOutlined, TagOutlined, WarningOutlined } from "@ant-design/icons";
import { Space, Modal, message, Button, Select, Form, Typography, Tag, Tooltip, Spin, Alert } from "antd";
import {
  generateFormRules,
  getErrorMessage,
  getStandardError,
  HeaderSection,
  IAppTableColumn,
  NotSet,
  PAGE_SIZE_OPTIONS,
} from "@qlibs/react-components";
import { httpRequest } from "../../helpers/api";
import { AppTable, useFetchList } from "@qlibs/react-components";
import RowFilter from "@qlibs/react-components/dist/Table/RowFilter";
import { AttendanceImportProps, AttendanceProps, AttendanceStatus, initialAttendance, LocationType } from "../../types/attendance.type";
import TextArea from 'antd/lib/input/TextArea';
import { UserProperties } from '../../services/openapi';
import { EventsProps } from '../../types/event.type';
import useAdditionalDataForList from '../../hooks/useAdditionalDataForList';
import ExcelParser, { RowData } from "../../components/ExcelParser";
import DownloadTemplateButton from "../../components/ExcelTemplate";
import Table, { ColumnsType } from "antd/es/table";
import { useNavigate } from "react-router-dom";
import usePermission from '../../hooks/usePermission';

type IAdditionalData = {[key: string]: {user?: UserProperties, event?: EventsProps}};
const {Text} = Typography;
type Props = {
  type: 'DAILY' | 'EVENT';
  talentId?: string;

  hideHeader?: boolean;
  hideActions?: boolean;
};
const Attendance = (props: Props) => {
  const navigate = useNavigate()
  const {isUserHasPermission} = usePermission()
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const [bulkAttendance, setBulkAttendance] = useState<AttendanceImportProps[]>([]);
  const [isLoadingAction, setIsLoadingAction] = useState(false);
  const templateHeaders = ["talentEmail", "checkInAt", "status", "remark"];
  const templateSamples = ["talent@email.com", "2024-09-27, 10:30", "PRESENT", "Sample Remark"]

  const {
    isLoading,
    setIsLoading,
    data,
    setData,
    pagination,
    handleSearch,
    fetchList,
    changePage,
    changeLimit,
    pageQueries,
    filterDropdown,
  } = useFetchList<AttendanceProps>({
    endpoint: "attendance",
    limit: +PAGE_SIZE_OPTIONS[0],
    httpRequest: httpRequest as any,
    initialQuery: {
      ...(props.talentId ? {userId: props.talentId} : {})
    },
  });

  const { isLoadingAdditionalData, additionalData } =
    useAdditionalDataForList<IAdditionalData>({
      id: 'attendanceId',
      data,
      injects: [
        {
          injectedId: 'userId',
          endpoint: '/users?filterStatus=active&roles=talent&userIds=',
          endpointId: 'userId',
          returnKey: 'user',
        },
        props.type === 'EVENT' ? {
          injectedId: 'eventId',
          endpoint: '/event?eventIds=',
          endpointId: 'eventId',
          returnKey: 'event',
        } : {injectedId: '', endpoint: '', returnKey: '', endpointId: ''},
      ].filter(item => item?.injectedId),
    });

  const deleteAttendance = async (id: string) => {
    try {
      await httpRequest.delete<any>("/attendance/" + id);

      message.success('Deleted successfully');
    } catch(err) {
      getStandardError(err, {showToast: true});
    }
  };

  const columns: (IAppTableColumn<AttendanceProps> | any)[] = [
    {
      title: 'CHECK IN AT',
      dataIndex: 'checkInAt',
      key: 'checkInAt',
      type: 'datetime',
    },
    props.talentId ? {} : {
      title: 'TALENT NAME',
      dataIndex: 'userFullName',
      key: 'userFullName',
      keyId: 'attendanceId',
      width: 200,
      type: 'detail',
      render: (value: string, record: AttendanceProps) =>
        isLoadingAdditionalData ? (
          <Spin spinning={true} />
        ) : (
          additionalData[record.attendanceId]?.user?.name
        ),
    },
    props.type === 'EVENT'
      ? {
          title: 'EVENT NAME',
          dataIndex: 'eventName',
          key: 'description',
          type: 'description',
        }
      : undefined,
    {
      title: 'STATUS',
      key: 'status',
      dataIndex: 'status',
      type: 'status',
      render: (currentStatus: AttendanceStatus, record: AttendanceProps) => (
        <Tooltip title="Click to change the status">
          <Tag
            style={{ cursor: 'pointer' }}
            onClick={() => {
              Modal.confirm({
                title: 'Change Status',
                content: (
                  <React.Fragment>
                    <Text>
                      Current Status: <Tag>{currentStatus}</Tag>
                    </Text>
                    <Form
                      onFinish={(values) => {
                        handleStatusChange(
                          record.attendanceId,
                          values.newStatus,
                          values.remark
                        );
                        Modal.destroyAll();
                      }}
                    >
                      <Form.Item
                        label="New Status"
                        name="newStatus"
                        style={{ marginTop: 10 }}
                        rules={generateFormRules('New status', ['required'])}
                      >
                        <Select
                          style={{ width: 120 }}
                          options={[
                            { value: 'PRESENT', label: 'PRESENT' },
                            { value: 'LATE', label: 'LATE' },
                            { value: 'SICK', label: 'SICK' },
                            { value: 'PERMISSION', label: 'PERMISSION' },
                            { value: 'ALPHA', label: 'ALPHA' },
                          ].filter((item) => item.value !== currentStatus)}
                        />
                      </Form.Item>

                      <Form.Item
                        name="remark"
                        required
                        rules={generateFormRules('Remark', ['required'])}
                      >
                        <TextArea placeholder="write the reason here..." />
                      </Form.Item>

                      <Button type="primary" htmlType="submit">
                        SUBMIT
                      </Button>
                    </Form>
                  </React.Fragment>
                ),
                onCancel: () => {
                  const list = [...data].map((item) => {
                    if (item.attendanceId === record.attendanceId) {
                      item.status = currentStatus;
                    }
                    return item;
                  });
                  setData(list);
                },
                footer: false,
                closable: true,
              });
            }}
            {...MAPPING_STATUS_PROPS[currentStatus]}
          >
            {currentStatus}
          </Tag>
        </Tooltip>
      ),
    },
    {
      title: 'CREATED AT',
      dataIndex: 'createdAt',
      key: 'createdAt',
      type: 'datetime',
    },
    {
      title: 'UPDATED AT',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      type: 'datetime',
    },
    props.hideActions ? {} : {
      title: 'ACTION',
      key: 'action',
      actions: [
        {
          key: 'detail',
          label: 'Detail',
          confirmation: false,
          onPress: (id: string) => {
            navigate(`daily-attendance/${id}`)
          },
        },
        // 'edit',
        isUserHasPermission(['ATTENDANCE.DELETE']) ? {
          key: 'delete',
          label: 'Delete',
          confirmation: true,
          onPress: (id: string) => {
            deleteAttendance(id || '');
          },
        } : undefined,
      ],
    },
  ].filter((item) => item);

  const previewColumns: ColumnsType<AttendanceImportProps> = [
    {
      title: 'TALENT EMAIL',
      dataIndex: 'email',
      key: 'email',
      width: 200,
      render: (email: string, record: AttendanceImportProps) => {
        return (
          <div>
            {record.email ? (
              record.email
            ) : (
              <Alert showIcon={true} message="Required" type="warning" />
            )}
          </div>
        );
      },
    },
    {
      title: 'CHECK IN',
      dataIndex: 'checkInAt',
      key: 'checkInAt',
      width: 200,
      render: (checkInAt: Date, record: AttendanceImportProps) => {
        return (
          <div>
            {record.checkInAt ? (
              record.checkInAt.toString() && record.checkInAt.toString() !== "Invalid Date" ? (
                record.checkInAt.toString()
              ) : ( <Alert showIcon={true} message="Invalid Date" type="warning"/>)
            ) : (
              <Alert showIcon={true} message="Required" type="warning" />
            )}
          </div>
        );
      },
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      width: 200,
      render: (status: string, record: AttendanceImportProps) => {
        return (
          <div>
            {record.status !== undefined ? (
              <Tag style={{ fontSize:"14px" }}>{record.status}</Tag>
            ) : record.invalidStatus !== undefined ? (
              <Alert showIcon={true} message={`${record.invalidStatus} is not a valid status`} type="warning" />
            ) : (
              <Alert showIcon={true} message="Data empty or invalid" type="warning" />
            )}
          </div>
        );
      },
    },
    {
      title: 'REMARK',
      dataIndex: 'remark',
      key: 'remark',
      render: (remark: string, record: AttendanceImportProps) => {
        return (
          <div
            style={{
              whiteSpace: 'pre-line',
              maxHeight: 100,
              overflowY: 'auto',
            }}
          >
            {record && record.remark ? (
              <div
                style={{ maxHeight: 45, overflowY: 'clip' }}
                dangerouslySetInnerHTML={{ __html: record.remark }}
              />
            ) : (
              <NotSet />
            )}
          </div>
        );
      },
    },
  ];

  const handleStatusChange = async (id: string, value: AttendanceStatus, remark: string) => {
    try {
      setIsLoading(true);

      const newData = {
        status: value,
        remark: remark
      };
      await httpRequest.patch('attendance/' + id +  '/status', newData);
      fetchList();

      message.success(`Update status successfully`);
      setIsLoading(false);
    } catch (error: any) {
      message.error(error.data.message);
    }
  };

  const handleParsedData = (data: RowData[]) => {
    const formattedData = data.map((row) => {
      let status: AttendanceStatus|undefined;
      let invalidStatus: string|undefined;
  
      switch (row.status) {
        case AttendanceStatus.PRESENT:
          status = AttendanceStatus.PRESENT;
          break;
        case AttendanceStatus.LATE:
          status = AttendanceStatus.LATE;
          break;
        case AttendanceStatus.SICK:
          status = AttendanceStatus.SICK;
          break;
        case AttendanceStatus.PERMISSION:
          status = AttendanceStatus.PERMISSION;
          break;
        case AttendanceStatus.ALPHA:
          status = AttendanceStatus.ALPHA;
          break;
        default:
          status = undefined
          invalidStatus = row.status?.toString();
          break;
      }
  
      return {
        email: row.talentEmail as string,
        checkInAt: row.checkInAt ? new Date(row.checkInAt) : undefined, 
        status: status,
        invalidStatus: invalidStatus? invalidStatus : undefined,
        remark: row.remark as string,
      };
    });
    console.log(formattedData);
    setBulkAttendance(formattedData);
  }

  // const isOkButtonDisabled = bulkCourses.some(
  //   (course) => !course.title || !course.trainer
  // );

  const previewBulkData = (data: Partial<AttendanceImportProps>[]): AttendanceImportProps[] => {
    return (data || []).map((item, index) => ({
      email: item.email,
      status: item.status,
      checkInAt: item.checkInAt,
      remark: item.remark,
    }));
  };

  const isOkButtonDisabled = bulkAttendance.some(
    (attendance) => !attendance.email || !attendance.status || !attendance.checkInAt || !(attendance.checkInAt.toString() !== "Invalid Date") || attendance.invalidStatus
  );

  const handleCreateAttendanceBulk = async () => {
    try {
      setIsLoadingAction(true);
      await httpRequest.post('/attendance/bulk', bulkAttendance);
      message.success(`Success import attendance`);
      setIsLoadingAction(false);
      window.location.reload();
    } catch (error) {
      setIsLoadingAction(false);
      message.error(getErrorMessage(error));
    }
  };

  return (
    <React.Fragment>
      {!props.hideHeader && (
        <HeaderSection
          icon={<TagOutlined />}
          title="Daily Attendances"
          subtitle="Manage all daily attendances here"
          rightAction={
            isUserHasPermission(['ATTENDANCE.CREATE']) ? (
              <Space>
                <Button
                  style={{
                    background: '#264284',
                    color: 'white',
                    marginRight: 20,
                  }}
                  type="primary"
                  href="/daily-attendance/add"
                >
                  Add Attendance
                </Button>
                <Button
                  style={{ padding: '0px 32px' }}
                  type="primary"
                  onClick={() => setIsImportModalOpen(true)}
                >
                  Import Attendance
                </Button>
              </Space>
            ) : undefined
          }
        />
      )}

      <RowFilter
        filterValues={{
          search: pageQueries.search,
          status:
            pageQueries.status === '1'
              ? 'active'
              : pageQueries.status === '0'
              ? 'inactive'
              : 'all',
        }}
        filters={[
          [
            {
              type: 'select',
              key: 'status',
              label: 'Status',
              options: [
                {
                  value: 'all',
                  label: 'All',
                },
                {
                  value: 'PRESENT',
                  label: 'Present',
                },
                {
                  value: 'LATE',
                  label: 'Late',
                },
                {
                  value: 'SICK',
                  label: 'Sick',
                },
                {
                  value: 'PERMISSION',
                  label: 'Permission',
                },
                {
                  value: 'ALPHA',
                  label: 'Alpha',
                },
              ],
              onChange: (value: any) => {
                filterDropdown({
                  status: value === 'all' ? '' : value,
                });
              },
              colSpan: 6,
            },
          ],
        ]}
      />

      <AppTable
        isLoading={isLoading}
        keyId="attendanceId"
        columns={columns}
        data={data}
        pagination={pagination}
        onChangePage={changePage}
        onChangeLimit={changeLimit}
      />

      <Modal
        title={
          bulkAttendance.length === 0
            ? 'Import Attendance'
            : 'Preview Attendance'
        }
        open={isImportModalOpen}
        onCancel={() => {
          setBulkAttendance([]);
          setIsImportModalOpen(false);
        }}
        onOk={handleCreateAttendanceBulk}
        okButtonProps={{
          disabled: isOkButtonDisabled || isLoadingAction,
        }}
        width={bulkAttendance.length === 0 ? 600 : 1000}
      >
        {bulkAttendance.length !== 0 ? (
          <>
            {bulkAttendance.length !== 0 && isOkButtonDisabled ? (
              <>
                <Alert
                  showIcon={true}
                  message="Imported data has empty/invalid mandatory field(s). Please close this window before doing another import."
                  type="error"
                />
              </>
            ) : (
              <>
                <Alert
                  showIcon={true}
                  message="Data import successful. Press OK to save"
                  type="success"
                />
              </>
            )}
            <Table
              dataSource={previewBulkData(bulkAttendance)}
              columns={previewColumns}
              rowKey="email"
              pagination={{
                pageSize: 8,
                showSizeChanger: false,
              }}
            />
          </>
        ) : (
          <>
            <p>Import attendance data here (.xlsx or .xls)</p>
            <Space direction="horizontal">
              <ExcelParser setParsedData={handleParsedData} />
              <DownloadTemplateButton
                headers={templateHeaders}
                samples={templateSamples}
                templateName="attendance"
              />
            </Space>
            <p style={{ marginTop: 12 }}>No file have been uploaded</p>
          </>
        )}
      </Modal>
    </React.Fragment>
  );
};

export default Attendance;

const MAPPING_STATUS_PROPS = {
  [AttendanceStatus.ALPHA]: {
    color: 'red',
    icon: <CloseCircleOutlined />,
  },
  [AttendanceStatus.LATE]: {
    color: 'gray',
    icon: <WarningOutlined />,
  },
  [AttendanceStatus.PERMISSION]: {
    color: 'purple',
    icon: <IssuesCloseOutlined />,
  },
  [AttendanceStatus.PRESENT]: {
    color: 'green',
    icon: <CheckCircleOutlined />,
  },
  [AttendanceStatus.SICK]: {
    color: 'orange',
    icon: <PauseCircleOutlined />,
  },
};