import { getStandardError } from '@qlibs/react-components';
import { Empty, Modal } from 'antd'
import { useEffect, useState } from 'react'
import { httpRequest } from '../../../helpers/api';
import Result from '../../quiz/Result'
import DetailResultByTalent from '../../quiz/Result/DetailResultByTalent';

export default function DailyAchievementResultByTalent({
  talentId,
  onClose,
  onCancel,
}: {
  talentId: string;
  onClose: () => void;
  onCancel: () => void;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [quizId, setQuizId] = useState<string>();

  useEffect(() => {
    fetchEvent();
  }, []);

  const fetchEvent = async () => {
    setIsOpen(true);
    setIsLoading(true);
    try {
      const resConfig = await httpRequest.get(
        'app-configs/DAILY_ACHIEVEMENT_QUIZ_ID'
      );

      if (resConfig?.data?.payload && resConfig?.data?.payload.value) {
        setQuizId(resConfig?.data?.payload.value);
      }
      setIsLoading(false);
    } catch (err) {
      getStandardError(err, { showToast: true });
      setIsLoading(false);
    }
  };

  return (
    <Modal
      open={isOpen}
      loading={isLoading}
      closable
      width={900}
      title="DAILY ACHIEVEMENT RESULTS"
      onClose={() => {
        setIsOpen(false);
        onClose();
      }}
      onCancel={() => {
        setIsOpen(false);
        onCancel();
      }}
      footer={false}
    >
      {!isLoading && (!quizId || !talentId) ? <Empty /> : false}
      {!isLoading && quizId && talentId ? (
        <DetailResultByTalent quizId={quizId} userId={talentId} hideHeader detailButtonMode="modal" />
      ) : (
        false
      )}
    </Modal>
  );
}