import React from 'react';
import { Button } from 'antd';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

type IProps = {
  headers: string[];
  samples?: string[];
  templateName: string
}

const generateTemplate = (headers: string[] = [], samples: string[] = [], templateName: string) => {
  const templateHeaders = Object.values(headers);
  let sampleRow: string[]
  if(samples.length === 0){
    sampleRow = templateHeaders.map((header) => `Sample ${header.charAt(0).toUpperCase() + header.slice(1)}`)
  }else{
    sampleRow = Object.values(samples)
  }


  const worksheetData = [templateHeaders, sampleRow];
  const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

  const defaultWch = 40;

  worksheet['!cols'] = Array.from({ length: templateHeaders.length }, () => ({ wch: defaultWch }))

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Template');

  const excelBuffer = XLSX.write(workbook, {
    bookType: 'xlsx',
    type: 'array',
  });

  const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
  saveAs(blob, `${templateName}_template.xlsx`);
};

const DownloadTemplateButton:React.FC<IProps> = ({ headers, samples, templateName }) => (
  <Button onClick={() =>generateTemplate(headers, samples, templateName)} type="primary">
    Download Template
  </Button>
);

export default DownloadTemplateButton;
