import {
  DownOutlined,
  LockOutlined,
  LogoutOutlined,
  UserOutlined,
  BellOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Badge,
  Button,
  Drawer,
  Dropdown,
  Menu,
  MenuProps,
  Space,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { generateInitialFromName } from "../helpers/generator";
import { getFullName } from "../helpers/name";
import { useAuthUser, useSignOut } from "react-auth-kit";
import NotificationList from "./NotificationList";
import { capitalizeFirstLetter } from "@qlibs/react-components";
import { ListLangType } from "../i18n/settings";
import { useCookies } from "react-cookie";
import { useTranslationCustom } from "../i18n/client";
import IdIcon from "../assets/icons/id.png";
import EnIcon from "../assets/icons/en.png";
import JpIcon from "../assets/icons/jp.png";
import { useDispatch, useSelector } from "react-redux";
import { changeLanguage } from "../redux/action/language";
import useLanguage from "../zustand/useLanguage";

const { Title } = Typography;

const UserAvatar = () => {
  const navigate = useNavigate();
  const signOut = useSignOut();
  const auth = useAuthUser();
  console.info("auth()", auth());
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  // const lng = useSelector((state: any) => state.language.language);
  const { lng, setLng } = useLanguage();

  console.log(`zustand: ${lng}`);

  const location = useLocation();
  const { pathname } = location;

  const [cookies, setCookie, removeCookie] = useCookies(["i18next"]);

  const [languageSelected, setLanguageSelected] = useState<ListLangType>(lng);

  const { t, i18n } = useTranslationCustom(lng, "language");

  const initial = generateInitialFromName(auth()?.name!);
  // const role = capitalizeFirstLetter(auth()?.userType!);
  let name = auth()?.name ? getFullName(auth()) : "";
  name = name
    ? name
        .split(" ")
        .map((value: any) => capitalizeFirstLetter(value))
        .join(" ")
    : "";

  const handleMenuClick = (e: any) => {
    if (e.key === "profile") {
      navigate("/profile");
    } else if (e.key === "change-password") {
      navigate("/change-password");
    } else {
      signOut();
      navigate("/login");
    }
  };

  const items: MenuProps = {
    onClick: handleMenuClick,
    items: [
      {
        key: "1",
        type: "group",
        label:
          "Welcome, " +
          (auth()?.name!.length > 15
            ? auth()?.name!.substring(0, 15) + "..."
            : auth()?.name),
        children: [
          {
            key: "profile",
            icon: <UserOutlined />,
            label: "My Profile",
          },
          {
            key: "change-password",
            icon: <LockOutlined />,
            label: "Change Password",
          },
          {
            key: "logout",
            icon: <LogoutOutlined />,
            label: "Logout",
          },
        ],
      },
    ],
  };

  const itemLanguages: MenuProps["items"] = [
    {
      key: "id",
      label: "ID",
    },
    {
      key: "en",
      label: "EN",
    },
    {
      key: "jp",
      label: "JP",
    },
  ];

  const handleMarkAllAsRead = () => {
    //
  };

  const handleLanguageChange = (selectedKeys: React.Key[]) => {
    const selectedLanguage = selectedKeys[0] as "id" | "en";
    setLanguageSelected(selectedLanguage);
  };

  useEffect(() => {
    i18n.changeLanguage(languageSelected);
    setCookie("i18next", languageSelected);
    setLng(languageSelected);
  }, [languageSelected]);

  useEffect(() => {
    if (cookies?.i18next) {
      setLanguageSelected(cookies?.i18next);
    }
  }, [cookies?.i18next]);

  return (
    <div
      style={{
        display: "flex",
        gap: 14,
        width: "100%",
        alignItems: "center",
      }}
    >
      {/* <Drawer
        title={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>Notification</div>
            <Button
              type="link"
              style={{ fontSize: 12 }}
              onClick={handleMarkAllAsRead}
            >
              Mark all as read
            </Button>
          </div>
        }
        onClose={() => setIsDrawerOpen(false)}
        open={isDrawerOpen}
      >
        <NotificationList />
      </Drawer>
      <Button type="text" onClick={() => setIsDrawerOpen(true)}>
        <Badge count={5}>
          <BellOutlined style={{ fontSize: 20 }} />
        </Badge>
      </Button>
      <Dropdown
        overlay={
          <Menu
            onClick={(val) => handleLanguageChange([val.key as React.Key])}
            selectedKeys={[languageSelected]}
          >
            {itemLanguages.map((item: any) => (
              <Menu.Item key={item.key}>{item.label}</Menu.Item>
            ))}
          </Menu>
        }
      >
        <Typography.Link>
          <Space className="flex items-center text-black">
            <div>
              <img
                src={
                  languageSelected === "id"
                    ? IdIcon
                    : languageSelected === "en"
                    ? EnIcon
                    : languageSelected === "jp"
                    ? JpIcon
                    : ""
                }
                height={13}
                width={20}
                style={{
                  marginTop: -2,
                  marginRight: 5,
                }}
                alt={languageSelected}
                title={languageSelected}
              />
            </div>
            <div style={{ width: 20 }}>{languageSelected?.toUpperCase()}</div>
            <DownOutlined />
          </Space>
        </Typography.Link>
      </Dropdown> */}

      <Dropdown menu={items}>
        <div
          style={{
            display: "flex",
            gap: 8,
            width: "100%",
            alignItems: "center",
          }}
        >
          <AppAvatar size="large">{initial}</AppAvatar>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              lineHeight: 1.5,
            }}
          >
            <span
              style={{
                marginBottom: 0,
                fontSize: 12,
                color: "#768499",
              }}
            >
              {/* {role} */}
            </span>
            <span
              style={{
                marginTop: 0,
                marginBottom: 0,
                fontSize: 14,
                fontWeight: 600,
              }}
            >
              {name.length > 13 ? name.substring(0, 10) + "..." : name}
            </span>
          </div>

          <Icon />
        </div>
      </Dropdown>
    </div>
  );
};

const AppAvatar = styled(Avatar)`
  background-color: ${({ theme }) => theme.colors.ash500};
  color: ${({ theme }) => theme.colors.black};
  margin-right: 7px;
`;

const Icon = styled(DownOutlined)`
  color: ${({ theme }) => theme.colors.charcoal800};
`;

export default UserAvatar;
