import {
  BaseResponseProps,
  HeaderSection,
  NotSet,
  getErrorMessage,
  DetailItem,
  formatDate,
} from "@qlibs/react-components";
import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import useDetailBreadcrumbs from "../../../hooks/useDetailBreadcrumbs";
import {
  AnswerProperties,
  EQuestionType,
  EQuizType,
  ESubmitType,
  QuizAttemptDetailProperties,
  QuizAttemptProperties,
} from "../../../types/quiz.type";
import { httpRequest } from "../../../helpers/api";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Flex,
  Modal,
  Radio,
  Row,
  Space,
  Spin,
  Table,
  Tag,
  Typography,
  message,
} from "antd";
import { renderTextQuestionType } from "../components/RenderQuestionType";
import SimpleDetailItem from "../../../components/SimpleDetailItem";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import ModalAddNotes from "../components/ModalAddNotes";
import { dateRangeInMinutes } from "../../../helpers/datetime";

const { Title, Text } = Typography;
interface ILocation {
  quizId: string;
  quizAttemptId: string;
}

type Props = {
  quizId?: string;
  quizAttemptId?: string;
  hideHeader?: boolean;
  onSuccessChangeNotes?: () => void;
};

export default function DetailResultQuiz({
  quizId: quizIdFromProps,
  quizAttemptId: quizAttemptIdFromProps,
  ...props
}: Props) {
  const { quizId: quizIdFromParams, quizAttemptId: quizAttemptIdFromParams } =
    useParams<keyof ILocation>() as ILocation;

  const quizId = quizIdFromProps || quizIdFromParams;
  const quizAttemptId = quizAttemptIdFromProps || quizAttemptIdFromParams;

  const { setBreadcrumbDetails } = useDetailBreadcrumbs();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [quizAttempt, setQuizAttempt] = React.useState<
    QuizAttemptProperties | undefined
  >(undefined);
  const [showSeeAnswerModal, setShowSeeAnswerModal] =
    React.useState<boolean>(false);

  const [isFetch, setIsFetch] = React.useState<boolean>(true);
  const [showModalAddNotes, setShowModalAddNotes] =
    React.useState<boolean>(false);

  const [selectedAttemptDetail, setSelectedAttemptDetail] = useState<
    QuizAttemptDetailProperties | undefined
  >(undefined);

  React.useEffect(() => {
    if (isFetch) {
      fetchQuizAttempt();

      setIsFetch(false);
    }
  }, [quizAttemptId, isFetch]);

  console.log("quizAttempt", quizAttempt);

  const fetchQuizAttempt = async () => {
    try {
      setIsLoading(true);
      const res = await httpRequest.get<
        BaseResponseProps<QuizAttemptProperties>
      >("/quiz-attempt/" + quizAttemptId);
      if (res && res.data && res.data.payload) {
        let quizAttemopt = {
          ...res.data.payload,
          attemptDetails: res.data.payload.attemptDetails
            .map((v) => {
              if (v?.choosenAnswerIds && v?.choosenAnswerIds.length > 0) {
                let choosenAnswerData: AnswerProperties[] = [];
                for (const id of v?.choosenAnswerIds) {
                  const find: any = v.metaQuestionAnswers.find(
                    (x: AnswerProperties) => x.answerId === id
                  );
                  choosenAnswerData.push(find);
                }

                return {
                  ...v,
                  choosenAnswerData,
                };
              }

              return {
                ...v,
                choosenAnswerData: [],
              };
            })
            .sort((a, b) => a.order - b.order),
        };
        setQuizAttempt(quizAttemopt);
        // setAttemptDetails(quizAttemopt.attemptDetails);
      }

      const bcDetails = [
        {
          field: "quizAttemptId",
          value: quizAttemptId,
          label: res.data.payload.id,
        },
      ];
      setBreadcrumbDetails(bcDetails);
      setIsLoading(false);
    } catch (error: any) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const columns = [
    {
      title: "NO",
      dataIndex: "order",
      key: "order",
      render: (_: any, __: any, idx: number) => <>{idx + 1}</>,
    },
    {
      title: "QUESTION TEXT",
      dataIndex: ["question", "questionText"],
      key: "questionText",
      render: (v: string, record: QuizAttemptDetailProperties) => (
        <>
          {v ? (
            <Space direction="vertical">
              <div
                className="reset-margin"
                dangerouslySetInnerHTML={{ __html: v }}
              />
              {record?.question?.questionType === EQuestionType.ESSAY ||
              record?.question?.questionType === EQuestionType.UPLOAD_FILE ||
              record?.question?.questionType === EQuestionType.INSERT_URL ? (
                <Space>
                  <Typography.Paragraph
                    ellipsis={{ rows: 2, expandable: false }}
                    style={{ marginBottom: 0 }}
                  >
                    <Typography.Text strong>Answer: </Typography.Text>
                    {record?.answerText}
                  </Typography.Paragraph>
                </Space>
              ) : null}

              {record?.question?.questionType ===
              EQuestionType.FILL_THE_BLANK_CORRECT_WRONG ? (
                <>
                  <Space>
                    <Typography.Paragraph
                      ellipsis={{ rows: 2, expandable: false }}
                      style={{ marginBottom: 0 }}
                    >
                      <Typography.Text strong>Answer: </Typography.Text>
                      {record?.answerText}
                    </Typography.Paragraph>
                  </Space>

                  <Space>
                    <Typography.Text strong>Answer Key:</Typography.Text>
                    <Typography.Text>
                      {record?.metaQuestionAnswers
                        .filter((k) => k.isCorrect)
                        .map((v) => v.answer)
                        .join(",")}
                    </Typography.Text>
                  </Space>
                </>
              ) : null}

              {record?.question?.questionType ===
                EQuestionType.MULTIPLE_CHOICE_CORRECT_WRONG_MULTIPLE ||
              record?.question?.questionType ===
                EQuestionType.MULTIPLE_CHOICE_FREE_CHOICE_MULTIPLE ? (
                <Space direction="vertical">
                  <Space>
                    <Typography.Text strong>Answer:</Typography.Text>
                    <Typography.Text>
                      {(
                        record?.choosenAnswerData.map((v) => v.answer) || []
                      ).join(",")}
                    </Typography.Text>
                  </Space>
                  <Space>
                    <Typography.Text strong>Answer Key:</Typography.Text>
                    <Typography.Text>
                      {record?.metaQuestionAnswers
                        .filter((k) => k.isCorrect)
                        .map((v) => v.answer)
                        .join(",")}
                    </Typography.Text>
                  </Space>
                </Space>
              ) : null}

              {record?.question?.questionType ===
                EQuestionType.MULTIPLE_CHOICE_CORRECT_WRONG_SINGLE ||
              record?.question?.questionType ===
                EQuestionType.MULTIPLE_CHOICE_FREE_CHOICE_SINGLE ? (
                <Space direction="vertical">
                  <Space>
                    <Typography.Text strong>Answer:</Typography.Text>
                    <Typography.Text>
                      {(
                        record?.choosenAnswerData.map((v) => v.answer) || []
                      ).join(",")}
                    </Typography.Text>
                  </Space>
                  <Space>
                    <Typography.Text strong>Answer Key:</Typography.Text>
                    <Typography.Text>
                      {record?.metaQuestionAnswers
                        .filter((k) => k.isCorrect)
                        .map((v) => v.answer)
                        .join(",")}
                    </Typography.Text>
                  </Space>
                </Space>
              ) : null}
            </Space>
          ) : (
            <NotSet />
          )}
        </>
      ),
    },
    {
      title: "QUESTION TYPE",
      dataIndex: ["question", "questionType"],
      key: "questionType",
      render: (v: EQuestionType) => <>{renderTextQuestionType(v)}</>,
    },

    quizAttempt?.quiz?.quizType !== EQuizType.SURVEY
      ? {
          title: "IS CORRECT",
          dataIndex: "isCorrect",
          key: "isCorrect",
          render: (v: boolean, record: QuizAttemptDetailProperties) => (
            <>
              {typeof v === "boolean" ? (
                v ? (
                  <CheckCircleFilled
                    style={{
                      color: "green",
                    }}
                  />
                ) : (
                  <CloseCircleFilled
                    style={{
                      color: "red",
                    }}
                  />
                )
              ) : (
                <NotSet />
              )}
            </>
          ),
        }
      : undefined,
    quizAttempt?.quiz?.quizType !== EQuizType.SURVEY
      ? {
          title: "SCORE",
          dataIndex: "score",
          key: "score",
          render: (v: number, record: QuizAttemptDetailProperties) => (
            <>
              {typeof v === "number" ? (
                v
              ) : record?.question?.questionType === EQuestionType.ESSAY ? (
                <Space>
                  <Button
                    danger
                    onClick={() => handleSubmitScore(false, record?.id)}
                    loading={isLoading}
                  >
                    Wrong
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => handleSubmitScore(true, record?.id)}
                    loading={isLoading}
                  >
                    Correct
                  </Button>
                </Space>
              ) : (
                <NotSet />
              )}
            </>
          ),
        }
      : undefined,
    {
      title: "ACTION",
      dataIndex: "action",
      key: "action",
      render: (v: string, record: QuizAttemptDetailProperties) => (
        <>
          <Button
            type="primary"
            onClick={() => {
              setSelectedAttemptDetail(record);
              setShowSeeAnswerModal(true);
            }}
          >
            See Detail
          </Button>
        </>
      ),
    },
  ].filter(Boolean);

  const handleSubmitScore = async (isCorrect: boolean, id: string) => {
    try {
      setIsLoading(true);

      await httpRequest.patch(`/quiz-attempt/${id}/update-score`, {
        isCorrect,
      });
      setIsFetch(true);
    } catch (error) {
      message.error(getErrorMessage(error));
    } finally {
      setIsLoading(false);
    }
  };

  const workingTime = useMemo(() => {
    let res;
    if (quizAttempt?.startAt && quizAttempt?.finishedAt) {
      res = dateRangeInMinutes(quizAttempt?.startAt!, quizAttempt?.finishedAt);
    }
    return res;
  }, [quizAttempt]);
  return (
    <React.Fragment>
      {!props.hideHeader && (
        <HeaderSection
          icon="back"
          title={`RESULT QUIZ "${quizAttempt?.quiz?.title}" BY ${quizAttempt?.user?.name}`}
          rightAction={
            <Button
              type="primary"
              onClick={() => {
                setShowModalAddNotes(true);
              }}
            >
              {quizAttempt?.notesFromMentor ? "Edit" : "Add"} Notes From Mentor
            </Button>
          }
        />
      )}
      <Spin spinning={isLoading}>
        <Card bordered={false}>
          <Row
            style={{
              marginBottom: 20,
            }}
          >
            <Col span={5}>
              <Title level={5}>Data Attempt</Title>
              <Text style={{ color: "#768499" }}>
                These are attempt detail, you can’t change here
              </Text>
            </Col>
            <Col offset={1} span={16}>
              <Row gutter={[12, 12]}>
                <Col span={12}>
                  <DetailItem label="Name" value={quizAttempt?.user?.name} />
                </Col>
                <Col span={12}>
                  <DetailItem
                    label="Role"
                    value={quizAttempt?.user?.role?.roleName}
                  />
                </Col>
                <Col span={12}>
                  <DetailItem label="Attempt" value={quizAttempt?.attempt} />
                </Col>
                <Col span={12}>
                  <DetailItem
                    label="Start At"
                    value={quizAttempt?.startAt!}
                    type="datetime"
                  />
                </Col>
                <Col span={12}>
                  <SimpleDetailItem label="Finished At">
                    <Flex align="center" gap="small">
                      {quizAttempt?.finishedAt ? (
                        <div>
                          {formatDate(
                            quizAttempt?.finishedAt,
                            "DD MMM YYYY HH:mm"
                          )}
                        </div>
                      ) : (
                        <NotSet />
                      )}
                      {quizAttempt?.isFinishedBySystem && (
                        <Tag color="red">Finished By System</Tag>
                      )}
                    </Flex>
                  </SimpleDetailItem>
                </Col>
                <Col span={12}>
                  {workingTime ? (
                    <DetailItem
                      label="Duration"
                      value={`${workingTime} Minutes`}
                    />
                  ) : (
                    <DetailItem label="Duration" />
                  )}
                </Col>

                <Col span={12}>
                  <DetailItem
                    label="Number of Questions"
                    value={quizAttempt?.attemptDetails?.length}
                  />
                </Col>
                {quizAttempt?.quiz?.quizType !== EQuizType.SURVEY && (
                  <Col span={12}>
                    <SimpleDetailItem label="Score">
                      {typeof quizAttempt?.score === "number"
                        ? quizAttempt?.score
                        : 0}{" "}
                      / 100
                    </SimpleDetailItem>
                  </Col>
                )}
                <Col span={12}>
                  <div style={{ margin: "10px 0px" }}>
                    <Flex align="center" gap="small">
                      <Text
                        style={{
                          display: "block",
                          marginBottom: 2,
                          fontSize: 14,
                          fontWeight: 400,
                          color: "#768499",
                        }}
                      >
                        Notes From Mentor
                      </Text>
                      {props.hideHeader && (
                        <Button
                          type="link"
                          style={{
                            fontSize: 12,
                            width: "initial",
                            padding: 0,
                          }}
                          onClick={() => {
                            setShowModalAddNotes(true);
                          }}
                        >
                          {quizAttempt?.notesFromMentor ? "Edit" : "Add"} Notes
                        </Button>
                      )}
                    </Flex>
                    {quizAttempt?.notesFromMentor ? (
                      <Text style={{ display: "block", fontSize: 14 }}>
                        {quizAttempt?.notesFromMentor}
                      </Text>
                    ) : (
                      <NotSet />
                    )}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Table
                columns={columns as any}
                dataSource={quizAttempt?.attemptDetails}
                pagination={false}
              />
            </Col>
          </Row>
        </Card>

        <ModalAddNotes
          open={showModalAddNotes}
          onClose={() => {
            setShowModalAddNotes(false);
          }}
          quizAttempt={quizAttempt!}
          onSuccess={() => {
            setIsFetch(true);

            if (props.onSuccessChangeNotes) {
              props.onSuccessChangeNotes();
            }
          }}
        />
        {/* <Modal
          width={520}
          open={showModalAddNotes}
          footer={false}
          onCancel={() => {
            setShowModalAddNotes(false);
            form.resetFields();
          }}
          title={`${quizAttempt?.notesFromMentor ? "Edit" : "Add"} Notes`}
        >
          <Form
            layout="vertical"
            autoComplete="off"
            name="formQuiz"
            form={form}
          >
            <Row>
              <Col span={24}>
                <Form.Item
                  name="score"
                  label="Score"
                  rules={generateFormRules('Score', ['required'])}
                >
                  <Input.TextArea
                    rows={5}
                    style={{
                      width: '100%',
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Button
                  block
                  type="primary"
                  onClick={addNotes}
                  loading={isLoading}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal> */}

        <Modal
          width={650}
          open={showSeeAnswerModal}
          footer={false}
          onCancel={() => {
            setShowSeeAnswerModal(false);
            // setCurrent(0);
            setSelectedAttemptDetail(undefined);
          }}
          title="Detail Answer"
        >
          <Row
            style={{
              marginTop: 16,
            }}
          >
            <Col span={1}>{selectedAttemptDetail?.order}.</Col>

            <Col span={20}>
              {selectedAttemptDetail?.question?.questionType ===
              EQuestionType.LOGBOOK ? (
                selectedAttemptDetail?.answerText ? (
                  <Row gutter={[12, 12]}>
                    <Col span={24}>
                      <Table
                        columns={JSON.parse(
                          selectedAttemptDetail?.answerText
                        ).answers.map((v: any) => ({
                          key: v.key,
                          title: v.title,
                          dataIndex: v.key,
                        }))}
                        dataSource={[
                          JSON.parse(
                            selectedAttemptDetail?.answerText
                          ).answers.reduce(
                            (acc: any, item: any) => {
                              acc[item.key] = item.jawaban;
                              return acc;
                            },
                            { key: "row1" }
                          ),
                        ]}
                        pagination={false}
                        locale={{
                          emptyText: "Logbook Table",
                        }}
                      >
                        <Table.Column></Table.Column>
                      </Table>
                    </Col>
                  </Row>
                ) : (
                  <NotSet />
                )
              ) : (
                <Space direction="vertical">
                  <Flex align="start" gap="small">
                    <div
                      className="reset-margin"
                      dangerouslySetInnerHTML={{
                        __html:
                          selectedAttemptDetail?.question?.questionText ?? "",
                      }}
                    ></div>

                    {typeof selectedAttemptDetail?.isCorrect === "boolean" ? (
                      selectedAttemptDetail?.isCorrect ? (
                        <CheckCircleFilled
                          style={{
                            color: "green",
                            fontSize: 18,
                          }}
                        />
                      ) : (
                        <CloseCircleFilled
                          style={{
                            color: "red",
                            fontSize: 18,
                          }}
                        />
                      )
                    ) : null}
                  </Flex>

                  {selectedAttemptDetail?.question?.questionType ===
                    EQuestionType.MULTIPLE_CHOICE_CORRECT_WRONG_MULTIPLE ||
                  selectedAttemptDetail?.question?.questionType ===
                    EQuestionType.MULTIPLE_CHOICE_FREE_CHOICE_MULTIPLE ? (
                    <Space direction="vertical">
                      {selectedAttemptDetail?.metaQuestionAnswers.map(
                        (a: AnswerProperties) => (
                          <Checkbox
                            key={a.answerId}
                            checked={(
                              selectedAttemptDetail?.choosenAnswerIds ?? []
                            ).includes(a.answerId)}
                          >
                            {a.answer}
                          </Checkbox>
                        )
                      )}
                      <Space>
                        <Typography.Text strong>Answer Key:</Typography.Text>
                        <Typography.Text>
                          {selectedAttemptDetail?.metaQuestionAnswers
                            .filter((k) => k.isCorrect)
                            .map((v) => v.answer)
                            .join(",")}
                        </Typography.Text>
                      </Space>
                    </Space>
                  ) : null}

                  {selectedAttemptDetail?.question?.questionType ===
                    EQuestionType.MULTIPLE_CHOICE_CORRECT_WRONG_SINGLE ||
                  selectedAttemptDetail?.question?.questionType ===
                    EQuestionType.MULTIPLE_CHOICE_FREE_CHOICE_SINGLE ? (
                    <Space direction="vertical">
                      {selectedAttemptDetail?.metaQuestionAnswers.map(
                        (a: AnswerProperties) => (
                          <Radio
                            key={a.answerId}
                            checked={(
                              selectedAttemptDetail?.choosenAnswerIds ?? []
                            ).includes(a.answerId)}
                          >
                            {a.answer}
                          </Radio>
                        )
                      )}
                      <Space>
                        <Typography.Text strong>Answer Key:</Typography.Text>
                        <Typography.Text>
                          {selectedAttemptDetail?.metaQuestionAnswers
                            .filter((k) => k.isCorrect)
                            .map((v) => v.answer)
                            .join(",")}
                        </Typography.Text>
                      </Space>
                    </Space>
                  ) : null}

                  {selectedAttemptDetail?.question?.questionType ===
                  EQuestionType.FILL_THE_BLANK_CORRECT_WRONG ? (
                    <>
                      <Space>
                        <Typography.Text strong>Answer:</Typography.Text>
                        <Typography.Text>
                          {selectedAttemptDetail?.answerText}
                        </Typography.Text>
                      </Space>

                      <Space>
                        <Typography.Text strong>Answer Key:</Typography.Text>
                        <Typography.Text>
                          {selectedAttemptDetail?.metaQuestionAnswers
                            .filter((k) => k.isCorrect)
                            .map((v) => v.answer)
                            .join(",")}
                        </Typography.Text>
                      </Space>
                    </>
                  ) : null}

                  {selectedAttemptDetail?.question?.questionType ===
                    EQuestionType.ESSAY ||
                  selectedAttemptDetail?.question?.questionType ===
                    EQuestionType.UPLOAD_FILE ||
                  selectedAttemptDetail?.question?.questionType ===
                    EQuestionType.INSERT_URL ? (
                    <Space>
                      <Typography.Paragraph style={{ marginBottom: 0 }}>
                        <Typography.Text strong>Answer: </Typography.Text>
                        {selectedAttemptDetail?.answerText}
                      </Typography.Paragraph>
                    </Space>
                  ) : null}
                </Space>
              )}
            </Col>
          </Row>
        </Modal>
      </Spin>
    </React.Fragment>
  );
}
