import React from "react";
import { useNavigate } from "react-router-dom";
import { UserOutlined, PlusOutlined } from "@ant-design/icons";
import { message, Button, Modal, Typography, Form, Tag, Radio } from "antd";
import { httpRequest } from "../../helpers/api";
import {
  AppTable,
  HeaderSection,
  IAppTableColumn,
  getErrorMessage,
  useFetchList,
  generateFormRules,
} from "@qlibs/react-components";
import usePermission from "../../hooks/usePermission";
import RowFilter from "@qlibs/react-components/dist/Table/RowFilter";
import { EQuizType, ESubmitType, QuizProperties } from "../../types/quiz.type";
import RenderQuizType, {
  renderTextQuizType,
} from "./components/RenderQuizType";
import { getMenu } from "../../const/menu";
import useAdditionalDataForList from "../../hooks/useAdditionalDataForList";
import { EEventType, EventsProps } from "../../types/event.type";
import EventDetailWithFunction from "../event/components/EventDetailWithFunction";
import { getBasePath } from "./helpers/menuPathGenerator";
import RenderSubmitType from "./components/RenderSubmitType";

const FEATURE = "QUIZ";

type IAdditionalData = {
  [key: string]: { events?: EventsProps[] };
};
type Props = {
  quizType: EQuizType[];
};
const ListQuiz = (props: Props) => {
  const { label, description } = getMenu();
  const navigate = useNavigate();
  const { isUserHasPermission } = usePermission();

  const {
    isLoading,
    data,
    pagination,
    changePage,
    changeLimit,
    handleSearch,
    filterDropdown,
    pageQueries,
    fetchList,
  } = useFetchList<QuizProperties>({
    httpRequest: httpRequest as any,
    endpoint: "quizzes",
    initialQuery: {
      quizType: props.quizType.join(","),
    },
    pageQuery: {
      defaultValue: {
        page: 1,
      },
    },
  });

  // const { isLoadingAdditionalData, additionalData } =
  //   useAdditionalDataForList<IAdditionalData>({
  //     id: 'quizId',
  //     data,
  //     injects: [
  //       {
  //         injectedId: 'quizId',
  //         endpoint: '/event?externalIds=',
  //         endpointId: 'externalId',
  //         returnKey: 'events',
  //         multiple: true,
  //       },
  //     ],
  //   });

  const [isLoadingAction, setIsLoadingAction] = React.useState(false);
  const [formCopy] = Form.useForm();
  const newQuizTypeWatch = Form.useWatch("newQuizType", formCopy);

  const handleCopy = async (id: string, newQuizType?: EQuizType) => {
    try {
      setIsLoadingAction(true);

      const res = await httpRequest.post(`/quizzes/${id}/copy`, {
        newQuizType,
      });

      const quiz = res.data.payload;

      let path = getBasePath();

      if (
        quiz.quizType === EQuizType.POSTEST ||
        quiz?.quizType === EQuizType.PRETEST
      ) {
        path = "pretest--posttest";
      } else if (
        quiz?.quizType === EQuizType.MICRO_LEARNING ||
        quiz?.quizType === EQuizType.MICRO_LEARNING_PUBLIC
      ) {
        path = "micro-learning";
      }

      navigate(`/${path}/${res.data.payload.quizId}/edit`);
    } catch (error: any) {
      message.error(getErrorMessage(error));
    } finally {
      setIsLoadingAction(false);
    }
  };

  const columns: IAppTableColumn<QuizProperties>[] = [
    {
      title: "TITLE",
      dataIndex: "title",
      key: "quizTitle",
      type: "detail",
      keyId: "quizId",
      showOriginalValue: true,
    },
    props.quizType.length > 1
      ? {
          title: "QUIZ TYPE",
          dataIndex: "quizType",
          key: "quizType",
          render: (v: EQuizType) => <>{<RenderQuizType type={v} />}</>,
        }
      : {},
    props.quizType.length > 0 && props.quizType.includes(EQuizType.SURVEY)
      ? {
          title: "SUBMIT TYPE",
          dataIndex: "submitType",
          key: "submitType",
          render: (v: ESubmitType) => <>{<RenderSubmitType type={v} />}</>,
        }
      : {},
    // {
    //   title: "REMIDIAL SCORE",
    //   dataIndex: "remidialScore",
    //   render: (v: number) => <>{v ? v : <NotSet />}</>,
    // },

    // {
    //   title: "MAX ATTEMPT",
    //   dataIndex: "maxAttempt",
    //   render: (v: number) => <>{v ? v : <NotSet />}</>,
    // },
    // {
    //   title: 'Related Events',
    //   key: 'relatedEvent',
    //   width: 200,
    //   render: (value, record: QuizProperties) =>
    //     isLoadingAdditionalData ? (
    //       <Spin />
    //     ) : additionalData[record.quizId]?.events &&
    //       (additionalData[record.quizId]?.events || []).length > 0 ? (
    //       <ul style={{ marginLeft: -30, fontSize: 12 }}>
    //         {(additionalData[record.quizId]?.events || []).map((item) => (
    //           <li>
    //             <a
    //               href="#"
    //               onClick={() => {
    //                 Modal.info({
    //                   title: 'Detail Event',
    //                   content: (
    //                     <EventDetailWithFunction eventId={item.eventId} />
    //                   ),
    //                   width: 800,
    //                   closable: true,
    //                   okText: 'EDIT EVENT',
    //                   onOk: () => {
    //                     navigate('/schedule/' + item.eventId);
    //                   },
    //                 });
    //               }}
    //             >
    //               <span>{item.title} </span>
    //               <span style={{ color: 'darkgrey' }}>
    //                 [
    //                 {getDateTimeRangeString(
    //                   item.startAt,
    //                   item.endAt,
    //                   'short',
    //                   'short'
    //                 )}
    //                 ]
    //               </span>
    //             </a>
    //           </li>
    //         ))}
    //       </ul>
    //     ) : (
    //       <div>
    //         <NotSet />
    //         {record.quizType === EQuizType.PRETEST ||
    //         record.quizType === EQuizType.POSTEST ? (
    //           <Button
    //             type="link"
    //             size="small"
    //             onClick={() => {
    //               if (record.quizType === EQuizType.PRETEST) {
    //                 navigate('/schedule/add', {
    //                   state: {
    //                     title: record.title,
    //                     eventType: EEventType.PRE_TEST_WSCC_TRAINING,
    //                     externalId: record.quizId,
    //                   },
    //                 });
    //               } else if (record.quizType === EQuizType.POSTEST) {
    //                 navigate('/schedule/add', {
    //                   state: {
    //                     title: record.title,
    //                     eventType: EEventType.POST_TEST_WSCC_TRAINING,
    //                     externalId: record.quizId,
    //                   },
    //                 });
    //               }
    //             }}
    //           >
    //             - Set Schedule -
    //           </Button>
    //         ) : (
    //           <></>
    //         )}
    //       </div>
    //     ),
    // },
    {
      title: "CREATED BY",
      dataIndex: "createdByUserId",
      key: "createdByUserId",
      render: (v: string, record: QuizProperties) => <>{record.user.name}</>,
    },

    {
      title: "CREATED AT",
      dataIndex: "createdAt",
      key: "createdAt",
      type: "datetime",
    },
    {
      title: "UPDATED AT",
      dataIndex: "updatedAt",
      key: "updatedAt",
      type: "datetime",
    },
    {
      title: "ACTION",
      key: "action",
      type: "actions",
      actions: [
        "detail",
        isUserHasPermission(['QUIZ.UPDATE']) ? "edit" : null,
        isUserHasPermission(['QUIZ.UPDATE']) ? {
          key: "copy",
          label: "Copy",
          // confirmation: {
          //   title: "Confirmation",
          //   content: "Are you sure want to copy this quiz?",
          // },
          onPress: (id) => {
            if (id) {
              const quiz = data.find((v) => v.quizId === id);

              if (quiz?.quizType === EQuizType.SURVEY) {
                Modal.confirm({
                  title: "Confirmation",
                  content: "Are you sure want to copy this quiz?",
                  closable: true,
                  onOk() {
                    handleCopy(id);
                  },
                });
              } else {
                Modal.confirm({
                  width: 625,
                  title: "Confirmation",
                  content: (
                    <React.Fragment>
                      <Typography.Text>
                        Current Quiz Type:{" "}
                        <RenderQuizType type={quiz?.quizType!} />
                      </Typography.Text>
                      <Form
                        form={formCopy}
                        onFinish={(values) => {
                          handleCopy(id, values.newQuizType);
                          Modal.destroyAll();
                        }}
                        layout="vertical"
                        initialValues={{
                          newQuizType: quiz?.quizType,
                        }}
                      >
                        <Form.Item
                          label="New Quiz Type"
                          name="newQuizType"
                          style={{ marginTop: 10, marginBottom: 12 }}
                          rules={generateFormRules("Quiz Type", ["required"])}
                        >
                          <Radio.Group>
                            {Object.values(EQuizType)
                              .filter((v) => v !== EQuizType.SURVEY)
                              .map((v) => (
                                <Radio value={v}>{renderTextQuizType(v)}</Radio>
                              ))}
                          </Radio.Group>
                        </Form.Item>
                        {(quiz?.quizType === EQuizType.POSTEST ||
                          quiz?.quizType === EQuizType.PRETEST) &&
                          (newQuizTypeWatch === EQuizType.MICRO_LEARNING ||
                            newQuizTypeWatch ===
                              EQuizType.MICRO_LEARNING_PUBLIC) && (
                            <Tag color="red">Ignore Essay</Tag>
                          )}

                        <div
                          style={{
                            color: "red",
                            fontSize: 12,
                            marginBottom: 12,
                          }}
                        >
                          * Essay questions are not copied when from pre test or
                          post test to micro learning or micro learning
                          (public).
                        </div>
                        <Button
                          type="primary"
                          htmlType="submit"
                          loading={isLoadingAction}
                        >
                          Copy
                        </Button>
                      </Form>
                    </React.Fragment>
                  ),
                  closable: true,
                  footer: false,
                  onCancel() {
                    formCopy.resetFields();
                  },
                });
              }
            }
          },
        } : undefined,
        isUserHasPermission(['QUIZ.DELETE']) ? {
          key: "delete",
          label: "Move to Archive",
          confirmation: {
            title: "Confirmation",
            content: "Are you sure want to archive this quiz?",
          },
          onPress: (id) => {
            if (id) {
              handleMoveToArchive(id);
            }
          },
        } : undefined,
      ],
    },
  ];

  const handleMoveToArchive = async (id: string) => {
    setIsLoadingAction(true);
    try {
      await httpRequest.delete(`/quizzes/${id}`);
      fetchList();
      message.success("Quiz move to archive.");
    } catch (error) {
      message.error(getErrorMessage(error));
    } finally {
      setIsLoadingAction(false);
    }
  };

  const rightAction: any = isUserHasPermission([FEATURE + ".CREATE"]) && (
    <Button
      icon={<PlusOutlined />}
      style={{ marginRight: 20 }}
      type="primary"
      onClick={() => {
        navigate({ pathname: "add" }, { relative: "route" });
      }}
    >
      Add {props.quizType.map((item) => renderTextQuizType(item)).join(" & ")}
    </Button>
  );

  return (
    <React.Fragment>
      <HeaderSection
        icon={<UserOutlined />}
        title={label}
        subtitle={description}
        rightAction={[rightAction]}
      />

      <RowFilter
        filterValues={{
          search: pageQueries.search,
          status: pageQueries.status || "all",
        }}
        filters={[
          [
            {
              type: "search",
              key: "search",
              label: "Search",
              placeholder: "Search by title",
              onChange: (value: any) => {
                handleSearch(value);
              },
              colSpan: 12,
            },
            // {
            //   type: "select",
            //   key: "categoryId",
            //   label: "Category",
            //   options: categories.map((cat) => ({
            //     value: cat.categoryId || "",
            //     label: cat.name,
            //   })),
            //   onChange: (value: any) => {
            //     filterDropdown({
            //       categoryId: value === "all" ? "" : value,
            //     });
            //   },
            //   colSpan: 6,
            // },
          ],
        ]}
      />

      <AppTable
        isLoading={isLoading || isLoadingAction}
        keyId="quizId"
        columns={columns.filter((v) => v.key)}
        data={data}
        pagination={pagination}
        onChangeLimit={changeLimit}
        onChangePage={changePage}
      />
    </React.Fragment>
  );
};

export default ListQuiz;
