import React from "react";
import {
  AppTable,
  HeaderSection,
  IAppTableColumn,
  useFetchList,
} from "@qlibs/react-components";
import { httpRequest } from "../../helpers/api";
import RowFilter from "@qlibs/react-components/dist/Table/RowFilter";
import { UserProperties } from '../user/types/user.type';

const ListAttendanceByTalent = () => {
  const filterRole = { roles: "talent" };

  const {
    isLoading,
    data,
    pagination,
    changePage,
    changeLimit,
    handleSearch,
    pageQueries,
    filterDropdown,
  } = useFetchList<UserProperties>({
    httpRequest: httpRequest as any,
    endpoint: 'users',
    limit: 99999,
    initialQuery: {
      ...filterRole,
      limit: 99999,
    },
    pageQuery: {
      defaultValue: {},
    },
  });

  const columns: IAppTableColumn<UserProperties>[] = [
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
      type: 'detail',
      keyId: 'userId',
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'PHONE NUMBER',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'LAST ATTENDANCE',
      dataIndex: 'attendance',
      key: 'attendance',
      render: (value, record) => {
        return record.name;
      }
    },
    {
      title: 'ACTION',
      key: 'action',
      type: 'actions',
      actions: ['detail'],
    },
  ];

  return (
    <React.Fragment>
      <HeaderSection title="Attendance By Talent" />

      <RowFilter
        filterValues={{
          search: pageQueries.search,
          status: pageQueries.status || "all",
        }}
        filters={[
          [
            {
              type: "search",
              key: "search",
              label: "Search",
              placeholder: "Search by name, email, or phone",
              onChange: (value: any) => {
                handleSearch(value);
              },
              colSpan: 18,
            },
            {
              type: "select",
              key: "status",
              label: "Status",
              options: [
                {
                  value: "all",
                  label: "All",
                },
                {
                  value: "active",
                  label: "Active",
                },
                {
                  value: "inactive",
                  label: "Inactive",
                },
              ],
              onChange: (value: any) => {
                filterDropdown({
                  status: value === "all" ? "" : value,
                });
              },
              colSpan: 6,
            },
          ],
        ]}
      />

      <AppTable
        isLoading={isLoading}
        keyId="userId"
        columns={columns}
        data={data}
        pagination={pagination}
        onChangePage={changePage}
        onChangeLimit={changeLimit}
        // _table={{
        //   scroll:{ x: '100%' }
        // } as any}
      />
    </React.Fragment>
  );
};
export default ListAttendanceByTalent;
