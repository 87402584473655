import { Tag } from "antd";
import { ESubmitType } from "../../../types/quiz.type";

export default function RenderSubmitType({ type }: { type: ESubmitType }) {
  const renderColor = (type: ESubmitType) => {
    switch (type) {
      case ESubmitType.DAILY:
        return "green";
      case ESubmitType.PER_EVENT:
        return "gold";
      default:
        return "volcano";
    }
  };
  return <Tag color={renderColor(type)}>{renderTextSubmitQuizType(type)}</Tag>;
}

export const renderTextSubmitQuizType = (type: ESubmitType): string => {
  switch (type) {
    case ESubmitType.DAILY:
      return "Daily";
    case ESubmitType.PER_EVENT:
      return "Per Event";
    default:
      return "General";
  }
};
